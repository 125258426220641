import { RFI_STATUS } from 'modules/kyc/constants/constants';

export const formatSummaryData = (data) =>
  [RFI_STATUS.RFI_REQUESTED, RFI_STATUS.RFI_SUBMITTED, RFI_STATUS.IN_REVIEW].map((status) => ({
    status: status,
    less_than_24_hours_count: data?.[status]?.less_than_24_hours_count,
    between_24_and_48_hours_count: data?.[status]?.between_24_and_48_hours_count,
    more_than_48_hours_count: data?.[status]?.more_than_48_hours_count,
  }));

export const formatRfiStatus = (status) => {
  switch (status) {
    case RFI_STATUS.RFI_REQUESTED:
      return 'REQUESTED';
    case RFI_STATUS.RFI_SUBMITTED:
      return 'SUBMITTED';
    case RFI_STATUS.IN_REVIEW:
      return 'IN REVIEW';
    default:
      return 'N/A';
  }
};

export const getRfiStatusBadgeColor = (status) => {
  switch (status) {
    case RFI_STATUS.RFI_REQUESTED:
      return 'blue';
    case RFI_STATUS.IN_REVIEW:
      return 'yellow';
    case RFI_STATUS.RFI_SUBMITTED:
      return 'green';
    default:
      return 'gray';
  }
};
