import React, { useMemo, useState } from 'react';
import { IoMdDownload } from 'react-icons/io';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { processSettlementFile } from 'api/settlement-reports';
import { showError } from 'utils/notifications';

const FileDetailsDrawer = ({ isDrawerOpen, setDrawerOpen, selectedReport, currentPage, setCurrentPage }) => {
  const [downloadingFile, setDownloadingFile] = useState(null);
  const toast = useToast();
  const itemsPerPage = 10;

  const handleDownloadFile = async (file) => {
    setDownloadingFile(file.path);
    try {
      const response = await processSettlementFile(file.path);

      if (response?.data?.data) {
        const { url, name } = response?.data?.data || {};
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        showError(toast, 'Error downloading file', 'Invalid response from server');
      }
    } catch (error) {
      showError(toast, 'Error downloading file', error);
    } finally {
      setDownloadingFile(null);
    }
  };

  const paginatedFiles = useMemo(() => {
    if (!selectedReport?.files) return [];
    const start = (currentPage - 1) * itemsPerPage;

    return selectedReport.files.slice(start, start + itemsPerPage);
  }, [selectedReport, currentPage]);

  return (
    <Drawer isOpen={isDrawerOpen} placement='right' onClose={() => setDrawerOpen(false)}>
      <DrawerOverlay />
      <DrawerContent minW={'fit-content'} maxW={'fit-content'}>
        <DrawerCloseButton />
        <DrawerHeader>{selectedReport?.client_name ?? 'Report Details'}</DrawerHeader>
        <DrawerBody>
          <TableContainer borderRadius='lg' border='1px solid' borderColor='gray.200' overflowX='auto' mt={4}>
            <Table variant='simple' size='sm'>
              <Thead bg='gray.100' position='sticky' top={0} zIndex={1}>
                <Tr>
                  <Th fontSize='sm' fontWeight='bold' color='gray.700' py={3}>
                    File Name
                  </Th>
                  <Th fontSize='sm' fontWeight='bold' color='gray.700' py={3}>
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedFiles.map((file, index) => (
                  <Tr key={index} fontSize={'sm'}>
                    <Td color='gray.600' fontWeight='bold'>
                      {file?.name}
                    </Td>
                    <Td>
                      <Button
                        onClick={() => handleDownloadFile(file)}
                        isLoading={downloadingFile === file?.path}
                        colorScheme='blue'
                        size='sm'
                        leftIcon={<IoMdDownload size={16} />}
                      >
                        Download
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          {selectedReport?.files?.length > itemsPerPage && (
            <Box display='flex' justifyContent='space-between' mt={4}>
              <Button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                isDisabled={currentPage === 1}
                colorScheme='blue'
                size='sm'
              >
                Previous
              </Button>
              <Button
                onClick={() => setCurrentPage((prev) => prev + 1)}
                isDisabled={currentPage * itemsPerPage >= selectedReport.files.length}
                colorScheme='blue'
                size='sm'
              >
                Next
              </Button>
            </Box>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default FileDetailsDrawer;
