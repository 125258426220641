export const COLORS = ['red', 'green', 'blue', 'purple', 'teal', 'orange', 'cyan', 'pink', 'yellow'];
export const STATUS_ITEMS = ['crypto_payins', 'crypto_payouts', 'fiat_payins', 'fiat_payouts'];

export const CURRENCY_OPTIONS = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'AED',
    value: 'AED',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'INR',
    value: 'INR',
  },
];
