import { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import { updateSuperMerchantConfig } from 'api/super-merchant';
import { getIsCorridorTemplateMappingAvailable } from 'api/templates';
import { CURRENCY_OPTIONS } from 'constants/onboarding.constants';
import { AVAILABLE_CORRIDORS_TABLE_HEADINGS } from 'modules/merchantinfo/constants/constants';
import { showError, showToast } from 'utils/notifications';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import CorridorFilter from 'components/CreateCorridors/CorridorFilter';
import CorridorPricingDialog from 'components/CreateCorridors/CorridorPricingDialog';
import CreatePayoutTemplateDrawer from 'components/CreateCorridors/CreatePayoutTemplateModal';
import SelectEntitiesCorridorTemplates from 'components/CreateCorridors/SelectEntitiesCorridorTemplates';
import Loader from 'components/Loader/Loader';

const CreateCorridorModal = ({
  isOpen,
  onClose,
  merchantId,
  fetchOnboardingConfig,
  entityId = '',
  isSuperMerchant = false,
}) => {
  const toast = useToast();
  const [availableCorridors, setAvailableCorridors] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCorridorId, setLoadingCorridorId] = useState(null);
  const [isCorridorPricingDialogOpen, setIsCorridorPricingDialogOpen] = useState(false);
  const [isSelectEntitiesCorridorTemplatesOpen, setIsSelectEntitiesCorridorTemplatesOpen] = useState(false);
  const [corridorId, setCorridorId] = useState(null);
  const [selectedEntitiesTemplates, setSelectedEntitiesTemplates] = useState([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [onBoardingConfig, setOnBoardingConfig] = useState({});
  const [updateLoading, setUpdateLoading] = useState(false);

  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedSourceCurrencies, setSelectedSourceCurrencies] = useState([]);
  const [selectedDestCurrencies, setSelectedDestCurrencies] = useState([]);

  const [sourceCurrency, setSourceCurrency] = useState('');
  const [destinationCurrency, setDestinationCurrency] = useState('');

  const [isCreatePayoutTemplateDrawerOpen, setIsCreatePayoutTemplateDrawerOpen] = useState(false);

  const [bypassCorridorTemplateMapping, setBypassCorridorTemplateMapping] = useState(false);

  const availableTypes = [...new Set(availableCorridors.map((corridor) => corridor.type))];
  const availableSourceCurrencies = [...new Set(availableCorridors.map((corridor) => corridor.source_currency_code))];
  const availableDestCurrencies = [...new Set(availableCorridors.map((corridor) => corridor.dest_currency_code))];

  const fetchAvailableCorridors = async () => {
    try {
      setIsLoading(true);
      const response = await internalToolsApi.getAvailableCorridors(merchantId);

      setAvailableCorridors(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const handleCorridorUpdate = (corridorId, data) => {
    const config = {
      ['corridors']: {
        ...onBoardingConfig?.corridors,
        [corridorId]: data.markupFees,
      },
      ['corridor_template_config']: {
        ...onBoardingConfig?.corridor_template_config,
        [corridorId]: data.templateId,
      },
    };

    setOnBoardingConfig(config);
  };

  const isCorridorTemplateMappingAvailable = async (id) => {
    try {
      setLoadingCorridorId(id);
      const response = await getIsCorridorTemplateMappingAvailable(id);

      setLoadingCorridorId(null);

      return response?.data?.data;
    } catch (error) {
      setLoadingCorridorId(null);
      showError(toast, `Error checking if templates for this corridor exist.`, error);
    }
  };

  const onClickCreateCorridor = async (id, sourceCurrency, destinationCurrency) => {
    const isPayoutTemplatesAvailable = await isCorridorTemplateMappingAvailable(id);

    if (isPayoutTemplatesAvailable) {
      openSelectEntitiesCorridorTemplates(id, sourceCurrency, destinationCurrency);
    } else {
      openCreatePayoutTemplateDrawer(id, sourceCurrency, destinationCurrency);
    }
  };

  const openSelectEntitiesCorridorTemplates = (id, sourceCurrency, destinationCurrency) => {
    setCorridorId(id);
    setSourceCurrency(sourceCurrency);
    setDestinationCurrency(destinationCurrency);
    setIsSelectEntitiesCorridorTemplatesOpen(true);
  };

  const openCorridorPricingDialog = (id, sourceCurrency, destinationCurrency) => {
    setCorridorId(id);
    setSourceCurrency(sourceCurrency);
    setDestinationCurrency(destinationCurrency);
    setIsSelectEntitiesCorridorTemplatesOpen(false);
    setIsCorridorPricingDialogOpen(true);
  };

  const openCreatePayoutTemplateDrawer = (id, sourceCurrency, destinationCurrency) => {
    setCorridorId(id);
    setSourceCurrency(sourceCurrency);
    setDestinationCurrency(destinationCurrency);
    setIsCreatePayoutTemplateDrawerOpen(true);
  };

  const closeCorridorPricingDialog = () => {
    setIsCorridorPricingDialogOpen(false);
  };

  const closeCreatePayoutTemplateDrawer = () => {
    setIsCreatePayoutTemplateDrawerOpen(false);
  };

  const handleSelectEntitiesCorridorTemplatesSubmit = (selectedTemplates) => {
    setSelectedEntitiesTemplates(selectedTemplates);
    openCorridorPricingDialog();
  };

  const handleCreateCorridor = async (corridorPricingData) => {
    if (
      corridorPricingData.markup ||
      corridorPricingData.layer2 ||
      corridorPricingData.fedwireFees ||
      corridorPricingData.swiftFees
    ) {
      const merchant_corridor_properties = {};
      let ABS, BPS;

      if (corridorPricingData.markup) {
        ABS = parseFloat(corridorPricingData.markup.ABS);
        BPS = parseFloat(corridorPricingData.markup.BPS);
        merchant_corridor_properties.markup_fees = { ABS, BPS };
      }
      if (corridorPricingData.layer2) {
        ABS = parseFloat(corridorPricingData.layer2.ABS);
        BPS = parseFloat(corridorPricingData.layer2.BPS);
        merchant_corridor_properties.layer2_fees = { ABS, BPS };
      }
      if (corridorPricingData.fedwireFees) {
        merchant_corridor_properties.fedwire_fees = parseFloat(corridorPricingData.fedwireFees);
      }
      if (corridorPricingData.swiftFees) {
        merchant_corridor_properties.swift_fees = parseFloat(corridorPricingData.swiftFees);
      }
      try {
        const payload = {
          merchant_id: merchantId,
          corridor_id: corridorId,
          merchant_corridor_properties,
          is_fedwire_fees_included: corridorPricingData.isFedwireFeesToBeCreatedOrUpdated,
          is_swift_fees_included: corridorPricingData.isSwiftFeesToBeCreatedOrUpdated,
          entity_and_template_group_id_list: selectedEntitiesTemplates,
        };

        await internalToolsApi.createCorridor(payload);
        showToast(toast, 'Success', `Successfully created corridor - ${corridorId} for the merchant.`, 'success');
        fetchAvailableCorridors();
      } catch (error) {
        showError(toast, `Error creating corridor for the merchant.`, error);
      }
    } else {
      showToast(toast, 'Cannot create account.', 'Enter pricing details to create the corridor.', 'info');
    }
  };

  const handleFilterChange = (filterName, selectedValues) => {
    switch (filterName) {
      case 'Type':
        setSelectedTypes(selectedValues);
        break;
      case 'Source Currency':
        setSelectedSourceCurrencies(selectedValues);
        break;
      case 'Destination Currency':
        setSelectedDestCurrencies(selectedValues);
        break;
      default:
        break;
    }
  };

  const filterOptions = {
    'Source Currency': availableSourceCurrencies,
    'Destination Currency': availableDestCurrencies,
    Type: availableTypes,
  };

  const filteredCorridors = availableCorridors.filter((corridor) => {
    const typeFilterPass = selectedTypes.length === 0 || selectedTypes.includes(corridor.type);
    const sourceCurrencyFilterPass =
      selectedSourceCurrencies.length === 0 || selectedSourceCurrencies.includes(corridor.source_currency_code);
    const destCurrencyFilterPass =
      selectedDestCurrencies.length === 0 || selectedDestCurrencies.includes(corridor.dest_currency_code);

    return typeFilterPass && sourceCurrencyFilterPass && destCurrencyFilterPass;
  });

  const handleUpdateSuperMerchantConfig = async () => {
    const payload = {
      ...onBoardingConfig,
      accounts_currencies: selectedCurrencies?.map((currency) => currency.value),
    };

    setUpdateLoading(true);

    try {
      await updateSuperMerchantConfig(merchantId, payload);
      showToast(toast, 'Success', 'Successfully updated corridor for the super-merchant.', 'success');
      fetchOnboardingConfig();
      onClose();
    } catch (error) {
      showError(toast, 'Error updating corridor for the super-merchant', error);
    } finally {
      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) fetchAvailableCorridors();
  }, [isOpen, merchantId]);

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isOpen={isOpen}
        onClose={onClose}
        size='full'
        scrollBehavior='inside'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Available Corridors</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody mt={4}>
            <Flex mb={4} gap={2}>
              {Object.keys(filterOptions).map((filterName) => (
                <CorridorFilter
                  key={filterName}
                  label={filterName}
                  options={filterOptions[filterName]}
                  selectedValues={
                    filterName === 'Type'
                      ? selectedTypes
                      : filterName === 'Source Currency'
                      ? selectedSourceCurrencies
                      : filterName === 'Destination Currency'
                      ? selectedDestCurrencies
                      : []
                  }
                  onChange={(selectedValues) => handleFilterChange(filterName, selectedValues)}
                />
              ))}
              {isSuperMerchant && (
                <Box flex='1'>
                  <Select
                    isMulti
                    placeholder='Select Currencies'
                    options={CURRENCY_OPTIONS}
                    value={selectedCurrencies}
                    onChange={(selectedOptions) => setSelectedCurrencies(selectedOptions)}
                  />
                </Box>
              )}
            </Flex>
            <Table variant='striped'>
              <Thead>
                <Tr>
                  {AVAILABLE_CORRIDORS_TABLE_HEADINGS.map((heading, idx) => (
                    <Th key={idx}>{heading}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {filteredCorridors?.map((corridor) => (
                  <Tr key={corridor?.id} fontSize='sm'>
                    <Td color='gray'>{corridor?.id ?? '-'}</Td>
                    <Td fontWeight='bold'>{corridor?.source_currency_code ?? '-'}</Td>
                    <Td fontWeight='bold'>{corridor?.dest_currency_code ?? '-'}</Td>
                    <Td>{corridor?.type ?? '-'}</Td>
                    <Td>
                      <Flex gap={2}>
                        <Button
                          size='sm'
                          variant='ghost'
                          colorScheme='blue'
                          onClick={() => {
                            if (isSuperMerchant) {
                              openCorridorPricingDialog(
                                corridor?.id,
                                corridor?.source_currency_code,
                                corridor?.dest_currency_code
                              );
                            } else {
                              openCreatePayoutTemplateDrawer(
                                corridor?.id,
                                corridor?.source_currency_code,
                                corridor?.dest_currency_code
                              );
                            }
                          }}
                        >
                          Create new template
                        </Button>
                        <Button
                          size='sm'
                          colorScheme='blue'
                          isLoading={loadingCorridorId === corridor?.id}
                          onClick={() =>
                            onClickCreateCorridor(
                              corridor?.id,
                              corridor?.source_currency_code,
                              corridor?.dest_currency_code
                            )
                          }
                        >
                          Create corridor
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            {!availableCorridors.length && !isLoading && !error && (
              <Center>
                <Text color='gray' mt={4}>
                  No available corridors found.
                </Text>
              </Center>
            )}

            {isLoading && <Loader />}
            {error && <AlertMessage errorTitle='Error fetching available corridors.' errorMessage={error?.message} />}
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>
              Close
            </Button>
            {isSuperMerchant && (
              <Button
                colorScheme='blue'
                ml={2}
                onClick={handleUpdateSuperMerchantConfig}
                isLoading={updateLoading}
                disabled={selectedCurrencies.length === 0 && Object.keys(onBoardingConfig).length === 0}
              >
                Update
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isCreatePayoutTemplateDrawerOpen && (
        <CreatePayoutTemplateDrawer
          isOpen={isCreatePayoutTemplateDrawerOpen}
          onClose={closeCreatePayoutTemplateDrawer}
          corridorId={corridorId}
          sourceCurrency={sourceCurrency}
          destinationCurrency={destinationCurrency}
          openCorridorPricingDialog={openCorridorPricingDialog}
          setBypassCorridorTemplateMapping={setBypassCorridorTemplateMapping}
        />
      )}

      {isSelectEntitiesCorridorTemplatesOpen && (
        <SelectEntitiesCorridorTemplates
          isOpen={isSelectEntitiesCorridorTemplatesOpen}
          onClose={() => setIsSelectEntitiesCorridorTemplatesOpen(false)}
          merchantId={merchantId}
          corridorId={corridorId}
          sourceCurrency={sourceCurrency}
          destinationCurrency={destinationCurrency}
          onboardedEntityId={entityId}
          onSubmit={handleSelectEntitiesCorridorTemplatesSubmit}
        />
      )}

      {isCorridorPricingDialogOpen && (
        <CorridorPricingDialog
          modalHeaderText={isSuperMerchant ? 'Update Corridor Pricing' : 'Create Corridor Pricing'}
          modalFooterBtnText={isSuperMerchant ? 'Update Corridor' : 'Create Corridor'}
          isSuperMerchant={isSuperMerchant}
          sourceCurrency={sourceCurrency}
          destinationCurrency={destinationCurrency}
          isOpen={isCorridorPricingDialogOpen}
          onClose={closeCorridorPricingDialog}
          onUpdate={handleCorridorUpdate}
          onSubmit={handleCreateCorridor}
          bypassCorridorTemplateMapping={bypassCorridorTemplateMapping}
          corridorId={corridorId}
          setBypassCorridorTemplateMapping={setBypassCorridorTemplateMapping}
        />
      )}
    </>
  );
};

export default CreateCorridorModal;
