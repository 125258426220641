import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { approveUnmappedCreditTransaction, refundUnmappedTransaction } from 'api/transactions';
import ConfirmationDialog from 'modules/credit-transactions/components/ConfirmationDialog';
import { ACTIONS } from 'modules/credit-transactions/constants/constants';
import { showError, showToast } from 'utils/notifications';

export default function UnmappedTransactionModal({ isOpen, onClose, transaction, refresh }) {
  const refundCommentDefault = 'Deposit came from a non-Whitelisted account';
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({
    accountId: '',
    beneName: '',
    beneAccountNumber: '',
    comments: refundCommentDefault,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState('');
  const toast = useToast();

  const updateFormField = (fieldName) => (e) => {
    const value = e.target.value;

    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleApprove = async () => {
    setIsSubmitting(true);
    try {
      await approveUnmappedCreditTransaction(transaction?.id, {
        account_id: formData?.accountId,
      });
      showToast(toast, `Successfully approved unmapped transaction - ${transaction?.id}`, '', 'success');
      handleClose();
      refresh();
    } catch (error) {
      showError(toast, `Error approving unmapped transaction - ${transaction?.id}`, error);
    }
    setIsSubmitting(false);
    closeConfirmation();
  };

  const handleRefund = async () => {
    setIsSubmitting(true);
    try {
      await refundUnmappedTransaction(transaction?.id, {
        beneficiary_name: formData?.beneName,
        beneficiary_account_number: formData?.beneAccountNumber,
        comments: formData?.comments,
      });
      showToast(toast, `Successfully initiated refund - ${transaction?.id}`, '', 'success');
      handleClose();
      refresh();
    } catch (error) {
      showError(toast, `Error initiating refund - ${transaction?.id}`, error);
    }
    setIsSubmitting(false);
    closeConfirmation();
  };

  const handleResetState = () => {
    setAction('');
    setFormData((prev) => ({ ...prev, accountId: '', comments: refundCommentDefault }));
  };

  const handleClose = () => {
    handleResetState();
    onClose();
  };

  const openConfirmation = (actionType) => {
    setConfirmationAction(actionType);
    setIsConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const getConfirmationProps = () => {
    if (confirmationAction === ACTIONS.APPROVE) {
      return {
        title: 'Confirm Approval',
        message: `Are you sure you want to map this transaction to account (${formData.accountId})?`,
        confirmLabel: 'Approve',
        confirmColorScheme: 'blue',
        onConfirm: handleApprove,
      };
    } else {
      return {
        title: 'Confirm Refund',
        message: `Are you sure you want to refund ${transaction?.source_currency_code} ${transaction?.source_amount} to ${formData.beneName}?`,
        confirmLabel: 'Refund',
        confirmColorScheme: 'red',
        onConfirm: handleRefund,
      };
    }
  };

  useEffect(() => {
    setFormData({
      accountId: '',
      beneName: transaction?.beneficiary_name,
      beneAccountNumber: transaction?.beneficiary_account_number,
      comments: refundCommentDefault,
    });
  }, [transaction]);

  const transactionDetails = [
    { label: 'Transaction ID', value: transaction?.id },
    { label: 'Amount', value: transaction?.source_amount },
    { label: 'Currency', value: transaction?.source_currency_code },
    { label: 'Beneficiary Name', value: transaction?.beneficiary_name },
    { label: 'Account Number', value: transaction?.beneficiary_account_number },
    { label: 'Source Account Address', value: transaction?.source_account_address },
  ];

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unmapped Transaction</ModalHeader>
          <ModalBody>
            <Grid templateColumns='repeat(2, 1fr)' gap={4} mb={6}>
              {transactionDetails.map(({ label, value }) => (
                <React.Fragment key={label}>
                  <GridItem>
                    <Text color='gray.600' fontWeight='medium'>
                      {label}
                    </Text>
                  </GridItem>
                  <GridItem>
                    <Text>{value || '-'}</Text>
                  </GridItem>
                </React.Fragment>
              ))}
            </Grid>

            {action === ACTIONS.APPROVE && (
              <>
                <FormControl>
                  <FormLabel>Account ID</FormLabel>
                  <Input
                    value={formData.accountId}
                    onChange={updateFormField('accountId')}
                    placeholder='Enter Account ID'
                  />
                </FormControl>
              </>
            )}

            {action === ACTIONS.REJECT && (
              <>
                <FormControl mb={4}>
                  <FormLabel>Beneficiary Name</FormLabel>
                  <Input
                    value={formData.beneName}
                    onChange={updateFormField('beneName')}
                    placeholder='Enter Beneficiary Name'
                  />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Account Name</FormLabel>
                  <Input
                    value={formData.beneAccountNumber}
                    onChange={updateFormField('beneAccountNumber')}
                    placeholder='Enter Account Name'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Comments (Optional)</FormLabel>
                  <Textarea
                    value={formData.comments}
                    onChange={updateFormField('comments')}
                    placeholder='Enter comments'
                    resize='vertical'
                  />
                </FormControl>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            {!action ? (
              <>
                <Button variant='ghost' onClick={handleClose} size='sm'>
                  Cancel
                </Button>
                <Button colorScheme='gray' ml={3} onClick={() => setAction(ACTIONS.REJECT)} size='sm'>
                  Refund
                </Button>
                <Button colorScheme='blue' ml={3} onClick={() => setAction(ACTIONS.APPROVE)} size='sm'>
                  Approve
                </Button>
              </>
            ) : action === ACTIONS.APPROVE ? (
              <>
                <Button variant='ghost' onClick={handleResetState} size='sm'>
                  Back
                </Button>
                <Button
                  colorScheme='blue'
                  ml={3}
                  onClick={() => openConfirmation(ACTIONS.APPROVE)}
                  isDisabled={!formData.accountId}
                  size='sm'
                >
                  Proceed with Approval
                </Button>
              </>
            ) : (
              <>
                <Button variant='ghost' onClick={handleResetState} size='sm'>
                  Back
                </Button>
                <Button
                  colorScheme='red'
                  ml={3}
                  onClick={() => openConfirmation(ACTIONS.REJECT)}
                  isDisabled={!formData.beneName || !formData.beneAccountNumber || !formData.comments}
                  size='sm'
                >
                  Proceed with Refund
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isConfirmationOpen && (
        <ConfirmationDialog
          isOpen={isConfirmationOpen}
          onClose={closeConfirmation}
          isLoading={isSubmitting}
          {...getConfirmationProps()}
        />
      )}
    </>
  );
}
