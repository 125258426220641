import { useEffect, useMemo, useState } from 'react';
import { Badge, Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { getWhitelistResourcesByStatus } from 'api/whitelisting';
import { TRANSACTION } from 'constants/transactionDetails';
import WhiteListResourceActionModal from 'modules/whitelist-resources/components/WhitelistResourceActionModal';
import { RESOURCE_WHITELIST_STATUS, WHITELIST_TABLE_HEADINGS } from 'modules/whitelist-resources/constants/constant';
import {
  formatWhitelistResourceStatus,
  getWhitelistResourceBadgeColorScheme,
} from 'modules/whitelist-resources/utils/utils';
import { formatDateTime } from 'utils/utils';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import Search from 'components/SearchBox/Search';

const WhiteListResources = () => {
  const [openActionModel, setOpenActionModel] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [whitelistResources, setWhitelistResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const filteredResourceList = useMemo(() => {
    if (!searchTerm) return whitelistResources;

    return whitelistResources?.filter((rfi) => {
      return (
        rfi?.entity_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        rfi?.merchant_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        rfi?.account_number?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [whitelistResources, searchTerm]);

  const handleRowClick = (resource) => {
    setSelectedResource(resource);
    setOpenActionModel(true);
  };

  const handleActionModelClose = () => {
    setOpenActionModel(false);
  };

  const fetchWhitelistResources = async () => {
    setLoading(true);
    try {
      const response = await getWhitelistResourcesByStatus(RESOURCE_WHITELIST_STATUS.IN_REVIEW);

      setWhitelistResources(response?.data?.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWhitelistResources();
  }, []);

  return (
    <Box overflowX='auto' maxWidth='100%' p={4} borderRadius='lg' boxShadow='sm' bg='white'>
      <Heading as='h1' size='md' mb={3} mt={4} color='gray.700'>
        WhiteList Resources
        {!!whitelistResources?.length && (
          <Badge colorScheme='gray' rounded='full' px={2.5} py={0.5} ml={2}>
            {whitelistResources?.length}
          </Badge>
        )}
      </Heading>

      {!!whitelistResources?.length && <Search searchType={TRANSACTION} setSearchTerm={setSearchTerm} />}
      <LoadingErrorWrapper isLoading={loading} errorTitle='Error fetching Whitelist resources' error={error}>
        <TableContainer borderRadius='lg' border='1px solid' borderColor='gray.200' overflowX='auto' mt={4}>
          <Table variant='simple' size='md'>
            <Thead bg='gray.100' position='sticky' top={0} zIndex={1}>
              <Tr>
                {WHITELIST_TABLE_HEADINGS.map((heading, idx) => (
                  <Th key={idx} fontSize='sm' fontWeight='bold' color='gray.700' py={3}>
                    {heading}
                  </Th>
                ))}
              </Tr>
            </Thead>
            {!!whitelistResources?.length && (
              <Tbody>
                {filteredResourceList?.map((resource) => (
                  <Tr
                    key={resource?.id}
                    fontSize='sm'
                    onClick={() => handleRowClick(resource)}
                    cursor='pointer'
                    _hover={{ bg: 'gray.50' }}
                  >
                    <Td color='gray.600' fontWeight='bold'>
                      {resource?.merchant_id ?? '-'}
                    </Td>
                    <Td color='gray.600' fontWeight={'bold'}>
                      {resource?.account_number ?? '-'}
                    </Td>
                    <Td color='gray.600' fontWeight={'bold'}>
                      {resource?.entity_name ?? '-'}
                    </Td>
                    <Td color='gray.600' fontWeight={'bold'}>
                      {resource?.partner_name}
                    </Td>
                    <Td>
                      <Badge colorScheme={getWhitelistResourceBadgeColorScheme(resource?.status)} rounded='full' px={3}>
                        {formatWhitelistResourceStatus(resource?.status)}
                      </Badge>
                    </Td>
                    <Td color='gray.600'>{formatDateTime(resource?.created_at)}</Td>
                    <Td color='gray.600'>{formatDateTime(resource?.updated_at)}</Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>
        {!whitelistResources?.length && (
          <Box textAlign='center' mt={4}>
            <Heading size='md' color='gray.500'>
              No Whitelist Resources found
            </Heading>
          </Box>
        )}
      </LoadingErrorWrapper>

      <WhiteListResourceActionModal
        openActionModel={openActionModel}
        handleActionModelClose={handleActionModelClose}
        selectedResource={selectedResource}
        fetchWhitelistResources={fetchWhitelistResources}
      />
    </Box>
  );
};

export default WhiteListResources;
