import { useEffect, useState } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { getMerchantCorridorPricing } from 'api/internaltools';
import { showError } from 'utils/notifications';

export default function CorridorPricingDialog({
  modalHeaderText,
  modalFooterBtnText,
  merchantMappingId,
  sourceCurrency,
  destinationCurrency,
  isOpen,
  onClose,
  onUpdate,
  onSubmit,
  modalInfoText,
  bypassCorridorTemplateMapping = false,
  setBypassCorridorTemplateMapping,
  isSuperMerchant = false,
  corridorId,
}) {
  const toast = useToast();
  const [markupFees, setMarkupFees] = useState({ ABS: '', BPS: '' });
  const [layer2Fees, setLayer2Fees] = useState({ ABS: '', BPS: '' });
  const [swiftFees, setSwiftFees] = useState('');
  const [fedwireFees, setFedwireFees] = useState('');
  const [isSwiftFeesToBeCreatedOrUpdated, setIsSwiftFeesToBeCreatedOrUpdated] = useState(false);
  const [isFedwireFeesToBeCreatedOrUpdated, setIsFedwireFeesToBeCreatedOrUpdated] = useState(false);
  const [errors, setErrors] = useState({}); // State for validation errors
  const [isLoading, setIsLoading] = useState(false);
  const [existingCorridorPricing, setExistingCorridorPricing] = useState({});
  const [templateId, setTemplateId] = useState('');

  const fetchCorridorPricing = async () => {
    try {
      const response = await getMerchantCorridorPricing(merchantMappingId);

      const { MarkupFees, Layer2Fees, WireFees, SwiftFees } = response?.data?.data || {};

      setExistingCorridorPricing({ MarkupFees, Layer2Fees, WireFees, SwiftFees });
    } catch (error) {
      showError(toast, 'Error getting existing corridor pricing.', error);
    }
  };

  const handleMarkupChange = (e) => {
    const { name, value } = e.target;

    setMarkupFees({ ...markupFees, [name]: value });
    // Clear validation error when both fields are filled
    if (markupFees.ABS && markupFees.BPS) {
      clearError(name);
    }
  };

  const handleLayer2Change = (e) => {
    const { name, value } = e.target;

    setLayer2Fees({ ...layer2Fees, [name]: value });
    // Clear validation error when both fields are filled
    if (layer2Fees.ABS && layer2Fees.BPS) {
      clearError(name);
    }
  };

  const handleSwiftChange = (e) => {
    const value = e.target.value;

    setSwiftFees(value);
    setIsSwiftFeesToBeCreatedOrUpdated(!!value);
  };

  const handleFedwireChange = (e) => {
    const value = e.target.value;

    setFedwireFees(value);
    setIsFedwireFeesToBeCreatedOrUpdated(!!value);
  };

  useEffect(() => {
    if (bypassCorridorTemplateMapping) {
      setBypassCorridorTemplateMapping(false);
    }
  }, [isOpen]);

  const clearError = (fieldName) => {
    const updatedErrors = { ...errors };

    delete updatedErrors[fieldName];
    setErrors(updatedErrors);
  };

  const validateFields = () => {
    const validationErrors = {};

    // Check if both ABS and BPS fields are filled for Markup Fees
    if ((markupFees.ABS && !markupFees.BPS) || (!markupFees.ABS && markupFees.BPS)) {
      validationErrors.markup = 'Both ABS and BPS fields must be filled for Markup Fees.';
    }

    // Check if both ABS and BPS fields are filled for Layer2 Fees
    if ((layer2Fees.ABS && !layer2Fees.BPS) || (!layer2Fees.ABS && layer2Fees.BPS)) {
      validationErrors.layer2 = 'Both ABS and BPS fields must be filled for Layer2 Fees.';
    }

    return validationErrors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateFields();

    if (Object.keys(validationErrors).length === 0) {
      // No validation errors, proceed with submission
      setIsLoading(true);
      const corridorPricingData = {};

      if (markupFees.ABS || markupFees.BPS) {
        corridorPricingData.markup = markupFees;
      }

      if (layer2Fees.ABS || layer2Fees.BPS) {
        corridorPricingData.layer2 = layer2Fees;
      }

      if (isFedwireFeesToBeCreatedOrUpdated) {
        corridorPricingData.fedwireFees = fedwireFees;
      }

      corridorPricingData.isFedwireFeesToBeCreatedOrUpdated = isFedwireFeesToBeCreatedOrUpdated;

      if (isSwiftFeesToBeCreatedOrUpdated) {
        corridorPricingData.swiftFees = swiftFees;
      }

      corridorPricingData.isSwiftFeesToBeCreatedOrUpdated = isSwiftFeesToBeCreatedOrUpdated;

      await onSubmit(corridorPricingData);
      setIsLoading(false);
      onClose();
    } else {
      // Validation errors found, set them in the state
      setErrors(validationErrors);
    }
  };

  const handleUpdate = () => {
    const payload = {
      markupFees: {
        ABS: Number(markupFees.ABS),
        BPS: Number(markupFees.BPS),
      },
      templateId: templateId,
    };

    onUpdate(corridorId, payload);
    onClose();
  };

  useEffect(() => {
    if (isOpen && merchantMappingId) {
      fetchCorridorPricing();
    }
    if (!isOpen) {
      setMarkupFees({ ABS: '', BPS: '' });
      setLayer2Fees({ ABS: '', BPS: '' });
      setFedwireFees('');
      setSwiftFees('');
      setIsFedwireFeesToBeCreatedOrUpdated(false);
      setIsSwiftFeesToBeCreatedOrUpdated(false);
      setErrors({});
    }
  }, [isOpen, merchantMappingId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align='center'>
            {modalHeaderText}
            {modalInfoText && (
              <Tooltip label={modalInfoText} placement='right'>
                <Icon as={InfoOutlineIcon} color='gray.500' ml={2} />
              </Tooltip>
            )}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={2} align='center' justify='center' mb={4}>
            <Tag size='sm' colorScheme='green'>
              {sourceCurrency}
            </Tag>
            <HiArrowRight />
            <Tag size='sm' colorScheme='blue'>
              {destinationCurrency}
            </Tag>
          </Flex>
          {merchantMappingId && !isSuperMerchant && (
            <Box mt={4} mb={4} bg='gray.100' borderRadius='8px' p={3}>
              <Text fontWeight='bold'> Existing Fees:</Text>
              <Text>
                Markup:{' '}
                {existingCorridorPricing?.MarkupFees
                  ? JSON.stringify(existingCorridorPricing?.MarkupFees, null, 2)
                  : '-'}
              </Text>
              <Text>
                Layer2:{' '}
                {existingCorridorPricing?.Layer2Fees
                  ? JSON.stringify(existingCorridorPricing?.Layer2Fees, null, 2)
                  : '-'}
              </Text>
              <Text>Fedwire: {existingCorridorPricing?.WireFees}</Text>
              <Text>Swift: {existingCorridorPricing?.SwiftFees}</Text>
            </Box>
          )}
          <VStack spacing={4}>
            <FormControl isInvalid={!!errors.markup}>
              <FormLabel>Markup Fees</FormLabel>
              <HStack spacing={2}>
                <Input
                  type='number'
                  name='ABS'
                  placeholder='ABS'
                  value={markupFees.ABS}
                  onChange={handleMarkupChange}
                />
                <Input
                  type='number'
                  name='BPS'
                  placeholder='BPS'
                  value={markupFees.BPS}
                  onChange={handleMarkupChange}
                />
              </HStack>
              <FormErrorMessage>{errors.markup}</FormErrorMessage>
            </FormControl>
            {isSuperMerchant && (
              <FormControl isInvalid={!!errors.layer2}>
                <FormLabel>Template Id</FormLabel>
                <HStack spacing={2}>
                  <Input
                    type='text'
                    name='templateId'
                    placeholder='Template Id'
                    value={templateId}
                    onChange={(e) => setTemplateId(e.target.value)}
                  />
                </HStack>
                <FormErrorMessage>{errors.layer2}</FormErrorMessage>
              </FormControl>
            )}
            {!isSuperMerchant && (
              <FormControl isInvalid={!!errors.layer2}>
                <FormLabel>Layer2 Fees</FormLabel>
                <HStack spacing={2}>
                  <Input
                    type='number'
                    name='ABS'
                    placeholder='ABS'
                    value={layer2Fees.ABS}
                    onChange={handleLayer2Change}
                  />
                  <Input
                    type='number'
                    name='BPS'
                    placeholder='BPS'
                    value={layer2Fees.BPS}
                    onChange={handleLayer2Change}
                  />
                </HStack>
                <FormErrorMessage>{errors.layer2}</FormErrorMessage>
              </FormControl>
            )}
            {!isSuperMerchant && (
              <FormControl>
                <FormLabel>Fedwire Fees </FormLabel>
                <Input type='number' placeholder='Fedwire Fees' value={fedwireFees} onChange={handleFedwireChange} />
              </FormControl>
            )}
            {!isSuperMerchant && (
              <FormControl>
                <FormLabel>Swift Fees</FormLabel>
                <Input type='number' placeholder='Swift Fees' value={swiftFees} onChange={handleSwiftChange} />
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant='ghost' size='sm' onClick={onClose} mr={2}>
            Cancel
          </Button>
          <Button
            size='sm'
            colorScheme='blue'
            onClick={isSuperMerchant ? handleUpdate : handleSubmit}
            isLoading={isLoading}
          >
            {modalFooterBtnText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
