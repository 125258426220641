import { instance } from 'api/internaltools';

export const getKycDetails = async (entityId) => {
  return instance.get(`/rfi/entity/${entityId}`);
};

export const updateKycDetails = async (entityId, questions) => {
  return instance.post(`/rfi/entity/${entityId}`, questions);
};

export const getRfiSummary = async () => {
  return instance.get('rfi/summary');
};
