import { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Center,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { getDebitTxnsScreeningDetails } from 'api/transactions';
import { TRANSACTION } from 'constants/transactionDetails';
import { formatTransactionStatus, getTransactionStatusBadgeColor } from 'modules/merchantinfo/utils/utils';
import { TRANSACTION_SCREENING_TABLE_HEADINGS } from 'modules/transaction-screening/constants/constants';
import { formatDateTime } from 'utils/utils';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import Search from 'components/SearchBox/Search';

export default function TransactionScreeningTable({ title = '' }) {
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredTransactionDetails = useMemo(() => {
    if (!searchTerm) return transactionDetails?.transactions;

    return transactionDetails?.transactions?.filter((transaction) => {
      return (
        transaction?.transaction_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transaction?.partner_code?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [transactionDetails, searchTerm]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setIsLoading(true);
        const response = await getDebitTxnsScreeningDetails();

        setTransactionDetails(response?.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <Box overflowX='auto' maxWidth='100%' p={4} borderRadius='lg' boxShadow='sm' bg='white'>
      <Heading as='h1' size='md' mb={3} mt={8}>
        {title}{' '}
        <Badge ml={1} colorScheme='blue' variant='solid' px={2}>
          {transactionDetails?.count}
        </Badge>
      </Heading>

      {transactionDetails?.count && <Search searchType={TRANSACTION} setSearchTerm={setSearchTerm} />}

      <LoadingErrorWrapper
        isLoading={isLoading}
        errorTitle='Error fetching transaction screening details.'
        error={error}
      >
        <TableContainer borderRadius='lg' border='1px solid' borderColor='gray.200' overflowX='auto' mt={4}>
          <Table variant='simple' size='md'>
            <Thead bg='gray.100' position='sticky' top={0} zIndex={1}>
              <Tr whiteSpace='nowrap'>
                {TRANSACTION_SCREENING_TABLE_HEADINGS.map((heading, idx) => (
                  <Th key={idx} fontSize='sm' fontWeight='bold' color='gray.700' py={3}>
                    {heading}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {filteredTransactionDetails?.map((transaction, idx) => (
                <Tr key={idx} fontSize='sm' cursor='pointer' _hover={{ bg: 'gray.50' }}>
                  <Td whiteSpace='nowrap' color='gray.600' fontWeight={'bold'}>
                    {transaction?.transaction_id ?? '-'}
                  </Td>
                  <Td whiteSpace='nowrap' color='gray.600'>
                    {formatDateTime(transaction?.date)}
                  </Td>
                  <Td whiteSpace='nowrap' color='gray.600'>
                    {transaction?.transfer_method ?? '-'}
                  </Td>
                  <Td whiteSpace='nowrap' color='gray.600' fontWeight={'bold'}>
                    {transaction?.partner_code ?? '-'}
                  </Td>
                  <Td whiteSpace='nowrap' color='gray.600'>
                    {transaction?.currency}
                  </Td>
                  <Td whiteSpace='nowrap' color='gray.600'>
                    {transaction?.amount}
                  </Td>
                  <Td whiteSpace='nowrap' color='gray.600'>
                    <Badge colorScheme={getTransactionStatusBadgeColor(transaction?.kyt_status)} rounded='0.8em' px={3}>
                      {formatTransactionStatus(transaction?.kyt_status)}
                    </Badge>
                  </Td>
                  <Td whiteSpace='nowrap' color='gray.600'>
                    <Badge
                      colorScheme={getTransactionStatusBadgeColor(transaction?.travel_rule_status)}
                      rounded='0.8em'
                      px={3}
                    >
                      {formatTransactionStatus(transaction?.travel_rule_status)}
                    </Badge>
                  </Td>
                  <Td whiteSpace='nowrap' color='gray.600'>
                    <Badge
                      colorScheme={getTransactionStatusBadgeColor(transaction?.chainalysis_status)}
                      rounded='0.8em'
                      px={3}
                    >
                      {formatTransactionStatus(transaction?.chainalysis_status)}
                    </Badge>
                  </Td>
                  <Td whiteSpace='nowrap' color='gray.600'>
                    <VStack>
                      <Text>{transaction?.whitelist_info?.whitelist_status}</Text>
                      <Text>{transaction?.whitelist_info?.whitelist_document_identifier}</Text>
                    </VStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {!transactionDetails?.count && (
          <Center>
            <Text color='gray' mt={4}>
              No transaction found.
            </Text>
          </Center>
        )}
      </LoadingErrorWrapper>
    </Box>
  );
}
