import { instance } from 'api/internaltools';

export const processInReviewCreditRefund = async (payload) => {
  return instance.post('refund/credit/in-review', payload);
};

export const processDebitRefundsByStatus = async (status, payload) => {
  return instance.post(`refund/debit/${status}`, payload);
};

export const getDebitRefundsDetailsByTxnId = async (txnId) => {
  return instance.get(`refund/debit/configs/${txnId}`);
};

export const getCreditRefundsDetailsByTxnId = async (txnId) => {
  return instance.get(`refund/credit/in-review/configs/${txnId}`);
};
