import React from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import MerchantTransactionsList from 'modules/merchantinfo/MerchantTransactionsList';
import {
  INITIATED_DELAYED_TRANSACTIONS_TITLE,
  STUCK_TRANSACTIONS_TITLE,
  SUCCEEDED_TRANSACTIONS_TITLE,
} from 'modules/transactions/constants/constants';

function TransactionsV2() {
  return (
    <Box mt={6} mb={3}>
      <Tabs isFitted variant='enclosed-colored' isLazy>
        <TabList>
          <Tab>Initiated / Delayed Transactions</Tab>

          <Tab>Stuck Transactions</Tab>

          <Tab>Succeeded Transactions</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <MerchantTransactionsList
              title={INITIATED_DELAYED_TRANSACTIONS_TITLE}
              fetchInitiatedTransactions
              showModifyStatusCTA
              showModifyJourneyCTA
              showStepLevelProgressCTA
              showScreeningDetailsCTA
              showTxnRefundCTA
            />
          </TabPanel>
          <TabPanel>
            <MerchantTransactionsList
              title={STUCK_TRANSACTIONS_TITLE}
              fetchInitiatedTransactions
              isFetchStuckTransactions
              showStepLevelProgressCTA
              showScreeningDetailsCTA
            />
          </TabPanel>
          <TabPanel>
            <MerchantTransactionsList
              title={SUCCEEDED_TRANSACTIONS_TITLE}
              fetchSucceededTransactions
              showStepLevelProgressCTA
              showTxnRefundCTA
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default TransactionsV2;
