import { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Button, Collapse, Flex, HStack, Icon, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { SIDEBARE_HIDDEN_ROUTES } from 'constants/routes';
import AccountMenu from 'components/AccountMenu/AccountMenu';
import IconBox from 'components/IconBox/IconBox';

const SidebarContent = ({ logo, routes }) => {
  let location = useLocation();

  const [openMenus, setOpenMenus] = useState({});

  const activeRoute = (routeName) => {
    return location.pathname === routeName;
  };

  const toggleMenu = (routeName) => {
    setOpenMenus((prev) => ({ ...prev, [routeName]: !prev[routeName] }));
  };

  const createLinks = (routes) => {
    const activeColor = useColorModeValue('gray.700', 'white');
    const inactiveColor = useColorModeValue('gray.400', 'gray.400');

    return routes.map((prop) => {
      if (!(prop?.path?.includes(':') || SIDEBARE_HIDDEN_ROUTES.includes(prop.path))) {
        const hasChildren = prop?.children && prop?.children?.length > 0;
        const isChildActive = hasChildren && prop?.children?.some((child) => activeRoute(child.layout + child.path));
        const isActive = activeRoute(prop.layout + prop.path);

        return (
          <Box key={prop?.name} w='100%'>
            {!hasChildren ? (
              <NavLink to={prop.layout + prop.path} key={prop.name}>
                <Button
                  boxSize='initial'
                  justifyContent='flex-start'
                  alignItems='center'
                  bg={isActive ? '#EDF2F6' : 'transparent'}
                  mb={{ xl: '12px' }}
                  mx={{ xl: 'auto' }}
                  ps={{ sm: '10px', xl: '16px' }}
                  py='12px'
                  borderRadius='10px'
                  w='100%'
                  _hover='none'
                  _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                >
                  <Flex>
                    {typeof prop?.icon === 'string' ? (
                      <Icon>{prop?.icon}</Icon>
                    ) : (
                      <IconBox
                        h='30px'
                        w='30px'
                        me='12px'
                        bg={isActive ? 'black' : 'transparent'}
                        color={isActive ? 'white' : 'black'}
                      >
                        {prop?.icon}
                      </IconBox>
                    )}
                    <Text color={isActive ? activeColor : inactiveColor} my='auto' fontSize='sm'>
                      {prop?.name}
                    </Text>
                    {hasChildren && <Icon as={openMenus[prop.name] ? BiChevronUp : BiChevronDown} ml='auto' />}
                  </Flex>
                </Button>
              </NavLink>
            ) : (
              <Button
                boxSize='initial'
                justifyContent='flex-start'
                alignItems='center'
                bg={isChildActive ? '#EDF2F6' : 'transparent'}
                mb={{ xl: '12px' }}
                mx={{ xl: 'auto' }}
                ps={{ sm: '10px', xl: '16px' }}
                py='12px'
                borderRadius='10px'
                w='100%'
                _hover='none'
                _active={{
                  bg: 'inherit',
                  transform: 'none',
                  borderColor: 'transparent',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
                onClick={() => toggleMenu(prop?.name)}
              >
                <HStack width={'100%'} justifyContent={'space-between'}>
                  <HStack>
                    {typeof prop?.icon === 'string' ? (
                      <Icon>{prop?.icon}</Icon>
                    ) : (
                      <IconBox
                        h='30px'
                        w='30px'
                        me='12px'
                        bg={isChildActive ? 'black' : 'transparent'}
                        color={isChildActive ? 'white' : 'black'}
                      >
                        {prop?.icon}
                      </IconBox>
                    )}

                    <Text color={isChildActive ? activeColor : inactiveColor} my='auto' fontSize='sm'>
                      {prop?.name}
                    </Text>
                  </HStack>
                  {hasChildren && (
                    <Icon
                      as={openMenus[prop.name] ? BiChevronUp : BiChevronDown}
                      ml={2}
                      h={6}
                      w={6}
                      color={isChildActive ? 'black' : 'gray.400'}
                    />
                  )}
                </HStack>
              </Button>
            )}

            {hasChildren && (
              <Collapse in={openMenus[prop?.name]} animateOpacity>
                <Stack pl={8} spacing={2}>
                  {prop?.children?.map((child) => (
                    <NavLink to={child.layout + child.path} key={child?.name} style={{ width: '100%' }}>
                      <Button
                        key={child?.name}
                        boxSize='initial'
                        justifyContent='flex-start'
                        alignItems='center'
                        bg={activeRoute(child?.layout + child?.path) ? '#EDF2F6' : 'transparent'}
                        mb={{ xl: '12px' }}
                        mx={{ xl: 'auto' }}
                        ps={{ sm: '10px', xl: '16px' }}
                        py='12px'
                        borderRadius='10px'
                        w='100%'
                        _hover='none'
                        _active={{
                          bg: 'inherit',
                          transform: 'none',
                          borderColor: 'transparent',
                        }}
                        _focus={{
                          boxShadow: 'none',
                        }}
                      >
                        <Flex>
                          <Text
                            color={activeRoute(child?.layout + child?.path) ? activeColor : inactiveColor}
                            my='auto'
                            fontSize='sm'
                          >
                            {child?.name}
                          </Text>
                        </Flex>
                      </Button>
                    </NavLink>
                  ))}
                </Stack>
              </Collapse>
            )}
          </Box>
        );
      }

      return null;
    });
  };

  return (
    <Flex direction='column' height='100vh'>
      <Box
        mt='25px'
        mb='15px'
        display='flex'
        lineHeight='100%'
        fontWeight='bold'
        justifyContent='center'
        alignItems='center'
        fontSize='11px'
      >
        <img src={logo} alt='Zamp logo' width='80px' />
      </Box>

      <Stack direction='column' flex='1' overflowY='auto'>
        {createLinks(routes)}
      </Stack>

      <Box align='center' mt='10px' mb='10px' p='2' bg={useColorModeValue('white', 'gray.800')}>
        <AccountMenu />
      </Box>
    </Flex>
  );
};

export default SidebarContent;
