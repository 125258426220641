import React, { useEffect, useState } from 'react';
import { TimeIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  List,
  ListItem,
  Select,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { updateTransactionRiskAlert } from 'api/transactions';
import { useAuthentication } from 'AuthContext';
import { getRiskAlertStatusColor, RISK_ALERT_STATUSES } from 'modules/transaction-risks/constants/constants';
import { showError, showToast } from 'utils/notifications';
import { formatDateTime } from 'utils/utils';
export default function AlertStatusHistory({ alert, onStatusUpdate }) {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [note, setNote] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const { user } = useAuthentication();

  useEffect(() => {
    setSelectedStatus('');
    setNote('');
  }, [alert?.id]);

  if (!alert) return null;

  const availableStatuses = RISK_ALERT_STATUSES.filter((status) => status !== alert.status);

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedStatus || !note.trim()) {
      return;
    }

    setIsSubmitting(true);
    try {
      const payload = {
        status: selectedStatus,
        actor_email: user?.email,
        note: note.trim(),
      };

      await updateTransactionRiskAlert(alert?.id, payload);

      showToast(toast, 'Success', `Alert status updated to ${selectedStatus}`, 'success');
      setSelectedStatus('');
      setNote('');
      if (onStatusUpdate) {
        onStatusUpdate();
      }
    } catch (err) {
      showError(toast, `Error updating alert status.`, err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      <VStack align='stretch' spacing={4} mb={6}>
        <Box>
          <Text fontWeight='bold' mb={1}>
            Rule Name:
          </Text>
          <Text>{alert?.rule_name}</Text>
        </Box>

        <Box>
          <Text fontWeight='bold' mb={1}>
            Current Status:
          </Text>
          <Badge colorScheme={getRiskAlertStatusColor(alert?.status)} fontSize='0.9em' p={1}>
            {alert?.status}
          </Badge>
        </Box>

        <Box>
          <Text fontWeight='bold' mb={1}>
            Message:
          </Text>
          <Text>{alert?.message}</Text>
        </Box>
      </VStack>

      <Box mb={6} p={4} borderWidth='1px' borderRadius='md' bg='gray.100'>
        <Heading as='h4' size='sm' mb={4}>
          Update Alert Status
        </Heading>

        <FormControl mb={3}>
          <FormLabel>New Status</FormLabel>
          <Select
            placeholder='Select status'
            value={selectedStatus}
            onChange={handleStatusChange}
            disabled={isSubmitting || availableStatuses.length === 0}
          >
            {availableStatuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Note (required)</FormLabel>
          <Textarea
            placeholder='Add a note explaining your decision...'
            value={note}
            onChange={handleNoteChange}
            disabled={isSubmitting}
            bg='white'
          />
        </FormControl>

        <Button
          colorScheme='blue'
          onClick={handleSubmit}
          isLoading={isSubmitting}
          isDisabled={!selectedStatus || !note.trim()}
          width='full'
        >
          Update Status
        </Button>
      </Box>

      <Box>
        <Heading as='h4' size='md' mb={3}>
          <HStack>
            <TimeIcon />
            <Text>Status Change History</Text>
          </HStack>
        </Heading>

        {alert?.status_changes && alert?.status_changes?.length > 0 ? (
          <List spacing={4}>
            {alert?.status_changes?.map((change, index) => (
              <ListItem key={index} p={3} borderWidth='1px' borderRadius='md'>
                <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                  <Box>
                    <Text fontWeight='bold' fontSize='sm'>
                      Previous Status:
                    </Text>
                    <Badge colorScheme={getRiskAlertStatusColor(change?.prev_status)} mt={1}>
                      {change?.prev_status}
                    </Badge>
                  </Box>
                  <Box>
                    <Text fontWeight='bold' fontSize='sm'>
                      New Status:
                    </Text>
                    <Badge colorScheme={getRiskAlertStatusColor(change?.new_status)} mt={1}>
                      {change?.new_status}
                    </Badge>
                  </Box>
                </Grid>

                <Box mt={3}>
                  <Text fontWeight='bold' fontSize='sm'>
                    Actor:
                  </Text>
                  <Text>{change?.actor_email || 'Not specified'}</Text>
                </Box>

                <Box mt={3}>
                  <Text fontWeight='bold' fontSize='sm'>
                    Note:
                  </Text>
                  <Text>{change?.note || 'No note provided'}</Text>
                </Box>

                <Box mt={3}>
                  <Text fontWeight='bold' fontSize='sm'>
                    Timestamp:
                  </Text>
                  <Text>{formatDateTime(change?.timestamp)}</Text>
                </Box>

                {index < alert?.status_changes?.length - 1 && <Divider mt={3} />}
              </ListItem>
            ))}
          </List>
        ) : (
          <Box p={4} borderWidth='1px' borderRadius='md'>
            <Text align='center' color='gray.500'>
              No status change history found.
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}
