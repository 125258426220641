import { RESOURCE_WHITELIST_STATUS } from 'modules/whitelist-resources/constants/constant';

export const formatWhitelistResourceStatus = (status) => {
  switch (status) {
    case RESOURCE_WHITELIST_STATUS.ACTIVE:
      return 'ACTIVE';
    case RESOURCE_WHITELIST_STATUS.REJECTED:
      return 'REJECTED';
    case RESOURCE_WHITELIST_STATUS.IN_REVIEW:
      return 'IN REVIEW';
    default:
      return 'N/A';
  }
};

export const getWhitelistResourceBadgeColorScheme = (status) => {
  switch (status) {
    case RESOURCE_WHITELIST_STATUS.ACTIVE:
      return 'green';
    case RESOURCE_WHITELIST_STATUS.REJECTED:
      return 'red';
    case RESOURCE_WHITELIST_STATUS.IN_REVIEW:
      return 'yellow';
    default:
      return 'gray';
  }
};
