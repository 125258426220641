export const ERROR_FETCHING_MERCHANTS = 'Error fetching merchants.';

export const INITIATED_DELAYED_TRANSACTIONS_TITLE = 'Initiated / Delayed Transactions';

export const STUCK_TRANSACTIONS_TITLE = 'Stuck Transactions';

export const SUCCEEDED_TRANSACTIONS_TITLE = 'Succeeded Transactions';

export const DEBIT_REVERSAL_TITLE = 'Debit reversal';
export const CREDIT_REVERSAL_TITLE = 'Credit reversal';
