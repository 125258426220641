export const KYC_TAB_TITLES = {
  RFI_SUMMARY: 'RFI Summary',
  REQUESTED_RFI: 'Requested RFIs',
  SUBMITTED_RFI: 'Submitted RFIs',
  IN_REVIEW_RFI: 'In Review KYC',
};
export const BY_ENTITY_ID = 'by Entity Id';

export const RFI_STATUS_TABLE_HEADERS = ['Date', 'Entity ID', 'Entity Name', 'RFI Status'];
export const RFI_SUMMARY_TABLE_HEADERS = ['RFI Summary', '<24 Hours', '24-48 Hours', '>48 Hours'];

export const RFI_STATUS = {
  RFI_SUBMITTED: 'rfi_submitted',
  RFI_REQUESTED: 'rfi_requested',
  IN_REVIEW: 'in_review',
};
