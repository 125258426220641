import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { getRfiSummary } from 'api/kyc';
import * as ROUTES from 'constants/routes';
import RFiList from 'modules/kyc/components/RfiList';
import { KYC_TAB_TITLES, RFI_STATUS } from 'modules/kyc/constants/constants';
import { formatSummaryData } from 'modules/kyc/utils/utils';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

function KycRfi() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rfiData, setRfiData] = useState({});
  const [entityId, setEntityId] = useState('');

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const activeTab = Number(queryParams.get('tab')) || 0;

  useEffect(() => {
    const fetchRfiSummary = async () => {
      setIsLoading(true);
      try {
        const response = await getRfiSummary();

        setRfiData(response?.data?.data || {});
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRfiSummary();
  }, []);

  const tabConfig = [
    { title: KYC_TAB_TITLES.RFI_SUMMARY, data: formatSummaryData(rfiData) },
    { title: KYC_TAB_TITLES.REQUESTED_RFI, data: rfiData?.rfi_requested?.rfi_summary_details },
    { title: KYC_TAB_TITLES.SUBMITTED_RFI, data: rfiData?.rfi_submitted?.rfi_summary_details },
    { title: KYC_TAB_TITLES.IN_REVIEW_RFI, data: rfiData?.in_review?.rfi_summary_details },
  ];

  const handleSummaryRowClick = (status) => {
    const statusToTabIndex = {
      [RFI_STATUS.RFI_REQUESTED]: 1,
      [RFI_STATUS.RFI_SUBMITTED]: 2,
      [RFI_STATUS.IN_REVIEW]: 3,
    };

    if (statusToTabIndex[status] !== undefined) {
      queryParams.set('tab', statusToTabIndex[status]);
      history.push({ search: queryParams.toString() });
    }
  };

  const handleSearch = (entityId) => {
    const route = `${ROUTES.KYC_RFI_ENTITY_DETAILS_ROUTE}${entityId}`;

    history.push(route, { entityId });
  };

  return (
    <Box mt={6} mb={3}>
      <Tabs
        isFitted
        variant='enclosed-colored'
        isLazy
        index={activeTab}
        onChange={(tab) => {
          queryParams.set('tab', tab);
          history.push({ search: queryParams.toString() });
        }}
      >
        <TabList>
          {tabConfig.map(({ title }, index) => (
            <Tab key={index}>{title}</Tab>
          ))}
        </TabList>

        <VStack spacing={4} align='stretch' mt={4} p={5}>
          <Grid templateColumns='repeat(2, 1fr)' gap={3}>
            <GridItem>
              <FormControl>
                <FormLabel>Entity ID</FormLabel>
                <Input
                  value={entityId}
                  onChange={(e) => setEntityId(e.target.value)}
                  placeholder='Enter Entity ID'
                  onKeyDown={(e) => e.key === 'Enter' && entityId && handleSearch(entityId)}
                />
              </FormControl>
            </GridItem>
            <GridItem alignSelf='end'>
              <Button colorScheme='blue' onClick={() => handleSearch(entityId)} w='fit-content'>
                Proceed
              </Button>
            </GridItem>
          </Grid>
        </VStack>

        <LoadingErrorWrapper isLoading={isLoading} errorTitle='Error fetching RFI summary.' error={error}>
          <TabPanels>
            {tabConfig.map(({ title, data }, index) => (
              <TabPanel key={index}>
                <RFiList
                  title={title}
                  rfiList={data}
                  isRfiSummary={title === KYC_TAB_TITLES.RFI_SUMMARY}
                  onSummaryRowClick={handleSummaryRowClick}
                  tab={activeTab}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </LoadingErrorWrapper>
      </Tabs>
    </Box>
  );
}

export default KycRfi;
