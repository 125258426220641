import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { getTransactionDetails } from 'api/transactions';
import { RISKY_TRANSACTIONS_ROUTE } from 'constants/routes';
import AlertStatusHistory from 'modules/transaction-risks/AlertStatusHistory';
import { getRiskAlertStatusColor } from 'modules/transaction-risks/constants/constants';
import { formatDateTime } from 'utils/utils';
import SkeletonErrorWrapper from 'components/SkeletonErrorWrapper/SkeletonErrorWrapper';

export default function TransactionRiskAlerts() {
  const { transactionId } = useParams();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [transaction, setTransaction] = useState(null);
  const [riskAlerts, setRiskAlerts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedAlert, setSelectedAlert] = useState(null);

  const fetchTransactionAlerts = async () => {
    setIsLoading(true);
    try {
      const response = await getTransactionDetails(transactionId, true);

      setTransaction(response?.data?.data?.transaction);
      setRiskAlerts(response?.data?.data?.risk_alerts);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTransactionAlerts();
  }, [transactionId]);

  const onStatusUpdate = () => {
    onClose();
    fetchTransactionAlerts();
  };

  const handleBack = () => {
    history.push(RISKY_TRANSACTIONS_ROUTE);
  };

  const handleAlertClick = (alert) => {
    setSelectedAlert(alert);
    onOpen();
  };

  const hoverBgColor = useColorModeValue('gray.100', 'gray.700');

  return (
    <SkeletonErrorWrapper isLoading={isLoading} error={error} errorTitle='Error loading transaction risk alerts'>
      <Box>
        <Flex justify='space-between' align='center' mb={4}>
          <Heading as='h1' size='lg'>
            Transaction Risk Alerts
          </Heading>
          <Button leftIcon={<ChevronLeftIcon />} onClick={handleBack} variant='outline'>
            Back
          </Button>
        </Flex>

        <Grid templateColumns='repeat(2, 1fr)' gap={6} mb={6}>
          <GridItem>
            <Box p={4} borderWidth='1px' borderRadius='md'>
              <Heading as='h3' size='sm' mb={3}>
                Transaction Details
              </Heading>
              <Text>
                <strong>Transaction ID:</strong> {transaction?.id}
              </Text>
              <Text>
                <strong>Status:</strong> {transaction?.status}
              </Text>
              <Text>
                <strong>Source Amount:</strong> {transaction?.source_amount}
              </Text>
              <Text>
                <strong>Receiving Amount:</strong> {transaction?.receiving_amount}
              </Text>
            </Box>
          </GridItem>

          <GridItem>
            <Box p={4} borderWidth='1px' borderRadius='md'>
              <Text>
                <strong>Beneficiary ID:</strong> {transaction?.beneficiary_id}
              </Text>
              <Text>
                <strong>Fixed Cost Currency:</strong> {transaction?.fixed_cost_currency}
              </Text>
              {transaction?.is_bulk_payout && (
                <Text>
                  <strong color='green.500'>Bulk Payout:</strong> Yes
                </Text>
              )}
            </Box>
          </GridItem>
        </Grid>

        <Heading as='h2' size='md' mb={3}>
          Alerts
        </Heading>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Rule Name</Th>
              <Th>Message</Th>
              <Th>Status</Th>
              <Th>Triggered At</Th>
              <Th>Last Updated At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {riskAlerts.map((alert) => (
              <Tr
                key={alert?.id}
                _hover={{ bg: hoverBgColor, cursor: 'pointer' }}
                borderBottom='1px'
                borderColor='gray.200'
                onClick={() => handleAlertClick(alert)}
              >
                <Td fontWeight='bold'>{alert?.rule_name}</Td>
                <Td>
                  <Text color='gray.700'>{alert?.message}</Text>
                </Td>
                <Td>
                  <Badge colorScheme={getRiskAlertStatusColor(alert?.status)}>{alert?.status}</Badge>
                </Td>
                <Td>{formatDateTime(alert?.created_at)}</Td>
                <Td>{formatDateTime(alert?.updated_at)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>Alert Status History</DrawerHeader>

          <DrawerBody>
            {selectedAlert && <AlertStatusHistory alert={selectedAlert} onStatusUpdate={onStatusUpdate} />}
          </DrawerBody>

          <DrawerFooter borderTopWidth='1px'>
            <Button variant='outline' mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </SkeletonErrorWrapper>
  );
}
