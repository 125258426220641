import { useEffect, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { getTransactionScreeningDetails, handleInReviewCreditTransaction } from 'api/transactions';
import {
  ACTION_TYPES,
  ACTIONS,
  STATUS_COLORS,
  TRANSACTION_TYPE_CREDIT,
  TRANSFER_METHOD_CRYPTO,
  TRANSFER_METHOD_FIAT,
} from 'modules/credit-transactions/constants/constants';
import CreditTransactionDetails from 'modules/credit-transactions/CreditTransactionDetails';
import RefundCreditTransactionModal from 'modules/credit-transactions/RefundCreditTransactionModal';
import RefundFiatCreditModal from 'modules/credit-transactions/RefundFiatCreditModal';
import { TRANSACTION_STATUS } from 'modules/merchantinfo/constants/constants';
import { formatTransactionStatus } from 'modules/merchantinfo/utils/utils';
import { showError, showToast } from 'utils/notifications';
import SkeletonErrorWrapper from 'components/SkeletonErrorWrapper/SkeletonErrorWrapper';

export default function TransactionScreeningDetailsDrawer({ isOpen, onClose, transaction, refresh }) {
  const [screeningDetails, setScreeningDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isApproving, setIsApproving] = useState(false);
  const [isRefundCreditTxnModal, setIsRefundModalOpen] = useState(false);
  const [isRefundFiatCreditModal, setIsRefundFiatCreditModal] = useState(false);
  const toast = useToast();

  const isCreditTransfer = transaction?.transfer_type?.toLowerCase() === TRANSACTION_TYPE_CREDIT;

  const fetchScreeningDetails = async () => {
    if (!transaction?.id) return;
    setIsLoading(true);
    try {
      const response = await getTransactionScreeningDetails(transaction?.id);

      setScreeningDetails(response?.data?.data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApproveBasisActionType = async (actionType) => {
    setIsApproving(true);
    try {
      await handleInReviewCreditTransaction(transaction?.id, {
        action: ACTIONS.APPROVE,
        action_type: actionType,
      });
      await fetchScreeningDetails();
      showToast(toast, 'Transaction approved successfully', 'success');
      showToast(toast, 'Success', `Transaction approved successfully, actionType - ${actionType}`, 'success');
    } catch (err) {
      showError(toast, `Error approving transaction, actionType - ${actionType}`, err);
    } finally {
      setIsApproving(false);
    }
  };

  useEffect(() => {
    fetchScreeningDetails();

    return () => {
      setScreeningDetails(null);
      setIsLoading(false);
      setError(null);
    };
  }, [transaction?.id]);

  const baseScreeningInfo = [
    {
      label: 'KYT Status',
      value: screeningDetails?.kyt_status,
      description: 'Know Your Transaction Status',
    },
    {
      label: 'Travel Rule Status',
      value: screeningDetails?.travel_rule_status,
      description: 'Compliance with travel rule regulations',
    },
  ];

  const screeningInfo = isCreditTransfer
    ? [
        ...baseScreeningInfo,
        {
          label: 'Chainalysis Status',
          value: screeningDetails?.chainalysis_status,
          description: 'Blockchain transaction analysis status',
          showApprove: screeningDetails?.chainalysis_status?.toLowerCase() === TRANSACTION_STATUS.FAILED,
          onApprove: () => handleApproveBasisActionType(ACTION_TYPES.CHAINALYSIS),
        },
      ]
    : baseScreeningInfo;

  const isKytFailed = screeningDetails?.kyt_status?.toLowerCase() === TRANSACTION_STATUS.FAILED;
  const isTravelRuleFailed = screeningDetails?.travel_rule_status?.toLowerCase() === TRANSACTION_STATUS.FAILED;
  const showSumsubNote = isKytFailed || isTravelRuleFailed;
  const isChainalysisUnknown = screeningDetails?.chainalysis_status?.toLowerCase() === TRANSACTION_STATUS.UNKNOWN;
  const isWhitelistInReview =
    screeningDetails?.whitelist_info?.whitelist_status?.toLowerCase() === TRANSACTION_STATUS.IN_REVIEW;
  const whitelistDocumentIdentifier = screeningDetails?.whitelist_info?.whitelist_document_identifier;

  const renderContent = () => {
    return (
      <SkeletonErrorWrapper isLoading={isLoading} error={error} errorTitle='Error loading screening details'>
        <CreditTransactionDetails transaction={transaction} partnerCode={screeningDetails?.partner_code} />

        {showSumsubNote && (
          <Alert status='warning' mb={6}>
            <AlertIcon />
            <Box>
              <Text>This transaction requires approval on Sumsub Dashboard for following:</Text>
              {isKytFailed && <Text>1. KYT Screening</Text>}
              {isTravelRuleFailed && <Text>2. Travel Rule Screening</Text>}
            </Box>
          </Alert>
        )}

        {isChainalysisUnknown && (
          <Alert status='info' mb={6}>
            <AlertIcon />
            <Text>Chainalysis status is unknown. Please check alerts manually on Chainalysis Dashboard.</Text>
          </Alert>
        )}

        <Divider mb={6} />

        <Box mb={6}>
          <Text fontSize='lg' fontWeight='semibold' mb={4}>
            Screening Statuses
          </Text>
          <Grid templateColumns='1fr' gap={6}>
            {screeningInfo?.map(({ label, value, description, showApprove, onApprove }) => (
              <Box key={label} p={4} borderWidth='1px' borderRadius='lg'>
                <Flex justify='space-between' align='center' mb={2}>
                  <Text fontWeight='medium'>{label}</Text>
                  <Flex align='center' gap={2}>
                    <Badge colorScheme={STATUS_COLORS[value?.toLowerCase()]}>
                      {formatTransactionStatus(value) || 'N/A'}
                    </Badge>
                    {showApprove && (
                      <Button size='sm' color='green' variant='outline' onClick={onApprove} isLoading={isApproving}>
                        Approve
                      </Button>
                    )}
                  </Flex>
                </Flex>
                <Text fontSize='sm' color='gray.600'>
                  {description}
                </Text>
              </Box>
            ))}
          </Grid>
        </Box>

        {screeningDetails?.whitelist_info?.whitelist_status && (
          <Box>
            <Text fontSize='lg' fontWeight='semibold' mb={4}>
              Whitelist Information
            </Text>
            <Box p={4} borderWidth='1px' borderRadius='lg'>
              <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                <GridItem>
                  <Text color='gray.600' fontWeight='medium'>
                    Status
                  </Text>
                </GridItem>
                <GridItem>
                  <Flex align='center' gap={2}>
                    <Badge
                      colorScheme={STATUS_COLORS[screeningDetails?.whitelist_info?.whitelist_status?.toLowerCase()]}
                    >
                      {formatTransactionStatus(screeningDetails?.whitelist_info?.whitelist_status) || 'N/A'}
                    </Badge>
                    {isWhitelistInReview && whitelistDocumentIdentifier && (
                      <Button
                        size='sm'
                        color='green'
                        variant='outline'
                        onClick={() => handleApproveBasisActionType(ACTION_TYPES.WHITELIST)}
                        isLoading={isApproving}
                      >
                        Approve
                      </Button>
                    )}
                  </Flex>
                </GridItem>
                {whitelistDocumentIdentifier ? (
                  <>
                    <GridItem>
                      <Text color='gray.600' fontWeight='medium'>
                        Document Identifier
                      </Text>
                    </GridItem>
                    <GridItem>
                      <Link href={whitelistDocumentIdentifier} color='blue.500' isExternal>
                        Supporting Document
                      </Link>
                    </GridItem>
                  </>
                ) : (
                  isWhitelistInReview && (
                    <GridItem colSpan={2}>
                      <Alert status='warning' mt={2}>
                        <AlertIcon />
                        <Text>Unable to find whitelist document identifier for this in-review transaction</Text>
                      </Alert>
                    </GridItem>
                  )
                )}
              </Grid>
            </Box>
          </Box>
        )}
      </SkeletonErrorWrapper>
    );
  };

  const isRefundCrypto = transaction?.transfer_method === TRANSFER_METHOD_CRYPTO;
  const isRefundFiat = transaction?.transfer_method === TRANSFER_METHOD_FIAT;

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size='lg'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex align='center' gap={4}>
            <Text>Transaction Screening Details</Text>
            {isCreditTransfer && isRefundCrypto && (
              <Button
                colorScheme='blue'
                size='sm'
                onClick={() => {
                  setIsRefundModalOpen(true);
                }}
              >
                Refund Crypto
              </Button>
            )}
            {isCreditTransfer && isRefundFiat && (
              <Button
                colorScheme='blue'
                size='sm'
                onClick={() => {
                  setIsRefundFiatCreditModal(true);
                }}
              >
                Refund FIAT
              </Button>
            )}
          </Flex>
        </DrawerHeader>
        <DrawerBody>{renderContent()}</DrawerBody>

        {isRefundCreditTxnModal && (
          <RefundCreditTransactionModal
            isOpen={isRefundCreditTxnModal}
            onClose={() => setIsRefundModalOpen(false)}
            transaction={transaction}
            partnerCode={screeningDetails?.partner_code}
          />
        )}
        {isRefundFiatCreditModal && (
          <RefundFiatCreditModal
            isOpen={isRefundFiatCreditModal}
            onClose={() => setIsRefundFiatCreditModal(false)}
            transaction={transaction}
            refresh={refresh}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
}
