import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import { getKycDetails } from 'api/kyc';
import KycRfiDetails from 'components/KycRfi/KycRfiDetails';
import KycRfiForm from 'components/KycRfi/KycRfiForm';
import KycRfiPreview from 'components/KycRfi/KycRfiPreview';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

export default function RfiEntityDetail() {
  const [entityId, setEntityId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [kycDetails, setKycDetails] = useState(null);
  const [error, setError] = useState(null);

  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const activeTab = Number(queryParams.get('tab')) || 0;

  const initialEntityId = location.state?.entityId || '';

  const fetchKycDetails = useCallback(async (id) => {
    if (!id) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await getKycDetails(id);
      const responseData = response?.data?.data;

      if (responseData) {
        setKycDetails({
          ...responseData,
          rfi_requested: responseData?.rfi_requested?.map((item) => ({
            ...JSON.parse(item?.value),
            created_at: item?.created_at,
            signed_rfi_document_urls: item?.signed_rfi_document_urls,
          })),
          rfi_submitted: responseData?.rfi_submitted?.map((item) => ({
            ...JSON.parse(item?.value),
            created_at: item?.created_at,
            signed_rfi_document_urls: item?.signed_rfi_document_urls,
          })),
        });
      } else {
        setKycDetails(null);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (initialEntityId) {
      setEntityId(initialEntityId);
      fetchKycDetails(initialEntityId);
    }
  }, [initialEntityId, fetchKycDetails]);

  return (
    <Container maxW='100%' py={8}>
      <HStack mb={3} spacing={10} align='center'>
        <Button colorScheme='gray' onClick={() => history.push(`/dashboard/kyc?tab=${activeTab}`)} size='md'>
          Back
        </Button>
        <Heading as='h1' size='lg' flex='1' textAlign='center'>
          KYC RFIs
        </Heading>
      </HStack>
      <VStack spacing={4} align='stretch'>
        <Grid templateColumns='repeat(2, 1fr)' gap={3}>
          <GridItem>
            <FormControl>
              <FormLabel>Entity ID</FormLabel>
              <Input
                value={entityId}
                onChange={(e) => setEntityId(e.target.value)}
                placeholder='Enter Entity ID'
                isDisabled={isLoading}
                onKeyDown={(e) => e.key === 'Enter' && entityId && fetchKycDetails(entityId)}
              />
            </FormControl>
          </GridItem>
          <GridItem alignSelf='end'>
            <Button
              colorScheme='blue'
              onClick={() => fetchKycDetails(entityId)}
              isLoading={isLoading}
              isDisabled={!entityId || isLoading}
              w='fit-content'
            >
              Proceed
            </Button>
          </GridItem>
        </Grid>
      </VStack>
      <LoadingErrorWrapper isLoading={isLoading} error={error}>
        {kycDetails && (
          <>
            <KycRfiPreview
              sumsub_applicant_id={kycDetails?.sumsub_applicant_id}
              entity_name={kycDetails?.entity_name}
              sumsub_kyc_status={kycDetails?.sumsub_kyc_status}
              kyc_status={kycDetails?.kyc_status}
              sumsub_profile_url={kycDetails?.sumsub_profile_url}
              entityId={entityId}
              fetchKycDetails={() => fetchKycDetails(entityId)}
            />
            <KycRfiForm
              entityId={entityId}
              fetchKycDetails={() => fetchKycDetails(entityId)}
              kyc_status={kycDetails?.kyc_status}
            />
            <KycRfiDetails kycDetails={kycDetails} />
          </>
        )}
      </LoadingErrorWrapper>
    </Container>
  );
}
