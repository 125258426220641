import React, { useEffect, useState } from 'react';
import { PiExcludeLight, PiIntersectLight } from 'react-icons/pi';
import { useHistory } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { getMerchantTransactions, getTransactions } from 'api/transactions';
import { TRANSACTIONS } from 'constants/merchantDetails';
import { TRANSACTION_RISK_ALERTS_ROUTE } from 'constants/routes';
import {
  TRANSACTION_LIMIT,
  TRANSACTION_STATUS,
  TRANSACTION_TABLE_HEADINGS,
} from 'modules/merchantinfo/constants/constants';
import { formatTransactionStatus, formatTransactionTime } from 'modules/merchantinfo/utils/utils';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Loader from 'components/Loader/Loader';
import Search from 'components/SearchBox/Search';

export default function TransactionRisksList({
  merchantId = '',
  title = '',
  alert_status = '',
}) {
  const [transactions, setTransactions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [excludeTestTransactions, setExcludeTestTransactions] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setIsLoading(true);
        let has_risk_alerts = true;
        let response;

        if (merchantId) {
          response = await getMerchantTransactions(merchantId, TRANSACTION_LIMIT, page);
        } else {
          response = await getTransactions(
            TRANSACTION_LIMIT,
            page,
            '',
            searchTerm,
            excludeTestTransactions,
            false,
            has_risk_alerts,
            alert_status
          );
        }

        setTransactions(response?.data?.data?.transactions ?? []);
        setTotalCount(response?.data?.data?.meta_data?.total_count ?? 0);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchTransactions();
  }, [page, TRANSACTION_LIMIT, merchantId, searchTerm, excludeTestTransactions]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const toggleExcludeTestTransactions = () => {
    setExcludeTestTransactions((prev) => !prev);
    setPage(1);
  };

  const handleRowClick = (transactionId) => {
    history.push(TRANSACTION_RISK_ALERTS_ROUTE(transactionId));
  };

  const totalPages = Math.ceil(totalCount / TRANSACTION_LIMIT);
  const isPreviousDisabled = page === 1;
  const isNextDisabled = page === totalPages;

  return (
    <Box overflowX='auto' maxWidth='100%'>
      {!merchantId && (
        <Flex justify='space-between'>
          <Search searchType={TRANSACTIONS} setSearchTerm={handleSearch} searchOnChangeEvent={false} width='30%' />
          <Button
            leftIcon={excludeTestTransactions ? <PiIntersectLight /> : <PiExcludeLight />}
            variant={excludeTestTransactions ? 'ghost' : 'solid'}
            colorScheme='gray'
            onClick={toggleExcludeTestTransactions}
          >
            {excludeTestTransactions ? 'Include Test Transactions' : 'Exclude Test Transactions'}
          </Button>
        </Flex>
      )}
      <Heading as='h1' size='md' mb={3} mt={8}>
        {title}
      </Heading>

      {isLoading && <Loader />}

      <Box overflowX='auto' maxWidth='100%' style={{ borderWidth: '2px', borderRadius: '18px' }} mt={4}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              {TRANSACTION_TABLE_HEADINGS.map((heading, idx) => (
                <Th key={idx}>{heading}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {transactions?.map((transaction) => (
              <Tr key={transaction?.id} fontSize='sm' onClick={() => handleRowClick(transaction?.id)} style={{ cursor: 'pointer' }} _hover={{ backgroundColor: 'gray.100' }}>
                <Td>{formatTransactionTime(transaction?.created_at)}</Td>
                <Td color='gray'>
                  {transaction?.id ?? '-'}
                </Td>
                <Td>{transaction?.title ?? '-'}</Td>
                <Td fontWeight='bold'>
                  {transaction?.transfer_type === 'debit' ? '-' + transaction?.amount : transaction?.amount}
                </Td>
                <Td color='gray'>{transaction?.currency_code ?? '-'}</Td>
                <Td color='gray'>{transaction?.transaction_method ?? '-'}</Td>
                <Td fontWeight='bold'>{transaction?.transfer_type ?? '-'}</Td>
                <Td>
                  <Badge
                    colorScheme={transaction?.status === TRANSACTION_STATUS.SUCCEEDED ? 'green' : 'gray'}
                    rounded='0.8em'
                    px={3}
                  >
                    {formatTransactionStatus(transaction?.status)}
                  </Badge>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {!transactions.length && !isLoading && !error && (
        <Center>
          <Text color='gray' mt={4}>
            No transactions found.
          </Text>
        </Center>
      )}

      {error && <AlertMessage errorTitle='Error fetching transactions.' errorMessage={error?.message} />}

      {totalPages > 0 && (
        <Box mt={4} display='flex' justifyContent='space-between' alignItems='center'>
          <Text>
            Showing {Math.min((page - 1) * TRANSACTION_LIMIT + 1, totalCount)} to{' '}
            {Math.min(page * TRANSACTION_LIMIT, totalCount)} of {totalCount}
          </Text>
          <Box>
            <Button size='sm' mr={2} onClick={() => handlePageChange(page - 1)} isDisabled={isPreviousDisabled}>
              Previous
            </Button>
            <Button size='sm' onClick={() => handlePageChange(page + 1)} isDisabled={isNextDisabled}>
              Next
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
