import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { updateWhitelistResourceStatus } from 'api/whitelisting';
import { TOAST_STATUS_SUCCESS } from 'constants/constants';
import { capitalize } from 'lodash';
import UploadOwnerDocumentInput from 'modules/merchantinfo/components/UploadOwnerDocumentInput';
import { INPUT_FILE_FORMATS } from 'modules/merchantinfo/constants/constants';
import { RESOURCE_WHITELIST_STATUS } from 'modules/whitelist-resources/constants/constant';
import { showError, showToast } from 'utils/notifications';

const WhiteListResourceActionModal = ({
  openActionModel,
  handleActionModelClose,
  selectedResource,
  fetchWhitelistResources,
}) => {
  const [loading, setLoading] = useState({
    approve: false,
    reject: false,
  });

  const [uploadSignedUrlResponse, setUploadSignedUrlResponse] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);

  const toast = useToast();

  const handleAction = async (action) => {
    if (action === RESOURCE_WHITELIST_STATUS.ACTIVE) {
      setLoading({ ...loading, approve: true });
    } else {
      setLoading({ ...loading, reject: true });
    }

    const payload = uploadSignedUrlResponse
      ? {
          status: action,
          documentKey: 'supporting_document',
          documentName: uploadSignedUrlResponse?.identifier,
          KybId: selectedResource?.kyb_id,
        }
      : {
          status: action,
        };

    try {
      await updateWhitelistResourceStatus(selectedResource?.id, payload);
      showToast(
        toast,
        capitalize(TOAST_STATUS_SUCCESS),
        `Resource ${action === RESOURCE_WHITELIST_STATUS.ACTIVE ? 'activated' : 'rejected'} successfully`,
        TOAST_STATUS_SUCCESS
      );
      fetchWhitelistResources();
      handleModalClose();
    } catch (error) {
      showError(toast, 'Failed to update resource status', error);
      setLoading({ approve: false, reject: false });
    }
  };

  const handleModalClose = () => {
    handleActionModelClose();
    setUploadSignedUrlResponse(null);
    setFileToUpload(null);
    setLoading({ approve: false, reject: false });
  };

  return (
    <Modal isOpen={openActionModel} onClose={handleModalClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius='lg'>
        <ModalHeader>Resource Action</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Upload Document (PDF/Image/Video)</FormLabel>
            <UploadOwnerDocumentInput
              merchantId={selectedResource?.merchant_id}
              kybId={selectedResource?.kyb_id}
              selectedDocumentType={'supporting_document'}
              isBusinessDocument={false}
              selectedOwnerIndex={0}
              fileToUpload={fileToUpload}
              setFileToUpload={setFileToUpload}
              uploadSignedUrlResponse={uploadSignedUrlResponse}
              setUploadSignedUrlResponse={setUploadSignedUrlResponse}
              acceptedFormats={`${INPUT_FILE_FORMATS.PNG}, ${INPUT_FILE_FORMATS.JPEG}, ${INPUT_FILE_FORMATS.JPG}, ${INPUT_FILE_FORMATS.PDF}, ${INPUT_FILE_FORMATS.BMP}, ${INPUT_FILE_FORMATS.MP4}`}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='green'
            mr={3}
            isLoading={loading?.approve}
            onClick={() => handleAction(RESOURCE_WHITELIST_STATUS.ACTIVE)}
          >
            Active
          </Button>
          <Button
            colorScheme='red'
            isLoading={loading?.reject}
            onClick={() => handleAction(RESOURCE_WHITELIST_STATUS.REJECTED)}
          >
            Reject
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WhiteListResourceActionModal;
