import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tag,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { getDebitRefundsDetailsByTxnId, processDebitRefundsByStatus } from 'api/refunds';
import { showError, showToast } from 'utils/notifications';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

export default function RefundDebitTxnModal({ isOpen, onClose, transaction, fetchTransactions }) {
  const [refundFormData, setRefundFormData] = useState({
    creditTransactionId: '',
    fees: 0,
  });
  const [refundTransactionList, setRefundTransactionList] = useState([]);
  const [refundTransactionsLoading, setRefundTransactionsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const handleRefundFormDataChange = (e, key) => {
    setRefundFormData({ ...refundFormData, [key]: e.target.value });
  };

  const handleRefund = async () => {
    if (!refundFormData?.creditTransactionId) {
      return;
    }

    const payload = {
      original_debit_transaction_id: transaction?.id,
      fees: Number(refundFormData?.fees),
      original_credit_transaction_id: refundFormData?.creditTransactionId,
    };

    setIsLoading(true);

    try {
      await processDebitRefundsByStatus(transaction?.status, payload);
      showToast(toast, 'Success', 'Refund initiated successfully.', 'success');
      fetchTransactions();
      onClose();
    } catch (e) {
      showError(toast, 'Error refunding transaction.', e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRefundDetails = async () => {
    setRefundTransactionsLoading(true);
    try {
      const response = await getDebitRefundsDetailsByTxnId(transaction?.id);
      const transactions = response?.data?.data?.Transactions || [];

      setRefundTransactionList(transactions);
      setRefundFormData({
        creditTransactionId: transactions[0]?.CreditTransactionId || '',
        fees: response?.data?.data?.Fees || '',
      });
    } catch (error) {
      showError(toast, 'Error fetching refund details.', error);
    } finally {
      setRefundTransactionsLoading(false);
    }
  };

  useEffect(() => {
    if (transaction?.id) {
      fetchRefundDetails();
    }
  }, [transaction?.id]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modify Transaction Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4} bg='gray.100' borderRadius='8px' p={3}>
            <Text mb={2} fontSize='sm'>
              Transaction ID: {transaction?.id}
            </Text>
            <Text fontWeight='bold' mb={2}>
              {transaction?.title} | Amt: {transaction?.amount} {transaction?.currency_code}
            </Text>
            <Text>
              Status:{' '}
              <Tag variant='solid' colorScheme='gray'>
                {transaction?.status}
              </Tag>
            </Text>
          </Box>
          <LoadingErrorWrapper isLoading={refundTransactionsLoading}>
            <VStack spacing={4} mt={4} alignItems={'start'}>
              <Text fontWeight={700} fontSize='md'>
                Refund the transaction with the following details.
              </Text>
              <Select
                placeholder='Select Transaction'
                onChange={(e) => handleRefundFormDataChange(e, 'creditTransactionId')}
              >
                {refundTransactionList?.map((txn) => (
                  <option key={txn?.CreditTransactionId} value={txn?.CreditTransactionId}>
                    {txn?.CreditTransactionId} - {txn?.CreditTransactionDate}
                  </option>
                ))}
              </Select>
              <FormControl>
                <FormLabel>Credit Transaction Id</FormLabel>
                <Input
                  value={refundFormData.creditTransactionId}
                  onChange={(e) => handleRefundFormDataChange(e, 'creditTransactionId')}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Fees</FormLabel>
                <Input
                  value={refundFormData.fees}
                  type='number'
                  onChange={(e) => handleRefundFormDataChange(e, 'fees')}
                />
              </FormControl>
            </VStack>
          </LoadingErrorWrapper>
        </ModalBody>
        <ModalFooter>
          <Button variant='ghost' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme='red'
            onClick={handleRefund}
            disabled={!refundFormData.creditTransactionId || !refundFormData.fees}
            isLoading={isLoading}
          >
            Proceed with Refund
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
