import { Checkbox, Flex, Text, VStack } from '@chakra-ui/react';
import { formatString } from 'utils/utils';
import KycRfiDocumentInfo from 'components/KycRfi/KycRfiDocumentInfo';

const KycRfiCard = ({ rfi, isSubmittedRfi }) => {
  const {
    prompt_format: {
      prompt,
      transaction_id,
      is_document_required,
      is_front_back_required,
      document_type,
      document_sub_type,
    },
    prompt: { notes, document },
    created_at,
    signed_rfi_document_urls,
  } = rfi;

  const date = new Date(created_at);
  const created_at_date = date.toLocaleDateString();
  const created_at_time = date.toLocaleTimeString();

  return (
    <VStack
      spacing={2}
      border={'1px solid'}
      borderColor={'gray.200'}
      p={4}
      borderRadius={'md'}
      background={'white'}
      alignItems={'start'}
      justifyItems={'center'}
      width={'100%'}
    >
      <VStack spacing={1} overflow={'hidden'} align={'start'} justifyItems={'center'} width={'100%'}>
        <Text>Question:</Text>
        <Text whiteSpace={'pre-wrap'}>{formatString(prompt)}</Text>
      </VStack>

      <Flex gap={2}>
        <Text>{isSubmittedRfi ? 'Submitted At:' : 'Created At:'}</Text>
        <Text>{`${created_at_date}, ${created_at_time}`}</Text>
      </Flex>

      {transaction_id && (
        <Flex gap={2}>
          <Text>Transaction Id:</Text>
          <Text>{transaction_id}</Text>
        </Flex>
      )}

      {document?.map((doc, index) => (
        <KycRfiDocumentInfo document={doc} key={index} url={signed_rfi_document_urls[index]} />
      ))}

      {notes && (
        <Flex gap={2}>
          <Text>Notes:</Text>
          <Text>{notes}</Text>
        </Flex>
      )}

      <Flex gap={4}>
        <Checkbox isChecked={is_document_required} disabled={true}>
          is document required
        </Checkbox>
        <Checkbox isChecked={is_front_back_required} disabled={true}>
          is both-side required
        </Checkbox>
      </Flex>

      <Flex gap={2}>
        <Text>Document Type:</Text>
        <Text wordBreak='break-word'>{document_type}</Text>
      </Flex>
      <Flex gap={2}>
        <Text>Document Subtype:</Text>
        <Text wordBreak='break-word'>
          {Array.isArray(document_sub_type) ? document_sub_type?.join(', ') : document_sub_type || 'N/A'}
        </Text>
      </Flex>
    </VStack>
  );
};

export default KycRfiCard;
