import React, { useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { generateUploadSignedUrl } from 'api/internaltools';
import { UPLOAD_FILE_ALLOWED_FORMATS } from 'constants/constants';
import {
  ENTITY_OWNER_DOCUMENT_UPLOAD_ID,
  FILE_EXTENSION_TO_INPUT_FORMAT_MAPPING,
  FILE_MIME_TYPE,
  FILE_SIZE,
} from 'modules/merchantinfo/constants/constants';

function UploadOwnerDocumentInput(props) {
  const {
    merchantId,
    kybId,
    isBusinessDocument,
    selectedOwnerIndex,
    selectedDocumentType,
    acceptedFormats,
    setFileToUpload,
    uploadSignedUrlResponse,
    setUploadSignedUrlResponse,
  } = props;

  const [isDocumentUploading, setDocumentUploading] = useState(false);
  const [error, setError] = useState(null);

  const handleUploadButtonClick = () => {
    document.getElementById(ENTITY_OWNER_DOCUMENT_UPLOAD_ID).click();
  };

  const handleUpload = async (file) => {
    const fileExtension = file?.name?.split('.')?.pop() ?? '';

    const fileName = merchantId + '_' + Date.now() + '.' + (FILE_MIME_TYPE[file?.type] ?? fileExtension);

    const acceptedFormatsArr = acceptedFormats.split(',').map((item) => item?.trim());
    const isAllowedFormat = acceptedFormatsArr.includes(FILE_EXTENSION_TO_INPUT_FORMAT_MAPPING[fileExtension]);

    if (!isAllowedFormat) {
      setError('* Invalid file format.');

      return;
    }

    setDocumentUploading(true);

    const payload = {
      fileExtension: FILE_MIME_TYPE[file?.type] ?? fileExtension,
      key: fileName,
      kybId,
      documentKey: selectedDocumentType,
      isBusinessDocument,
      companyOwner: !isBusinessDocument ? `company_owners[${selectedOwnerIndex}]` : '',
    };

    try {
      const response = await generateUploadSignedUrl(payload);
      const { identifier, url } = response?.data?.data || {};

      setFileToUpload(file);
      setUploadSignedUrlResponse({ identifier, url });
    } catch (err) {
      setError(`* Error: ${err?.message}` ?? '* Failed to upload document.');
    }

    setDocumentUploading(false);
  };

  const handleDocumentChange = (file) => {
    if (!file) return;

    setError(null);

    if (file?.size > FILE_SIZE.TWO_MB) {
      const err = `* File size cannot exceed more than ${FILE_SIZE.TWO_MB / FILE_SIZE.ONE_MB}MB.`;

      setError(err);

      return;
    }

    handleUpload(file);
  };

  const validFileExtensions = acceptedFormats
    ?.split(', ')
    ?.map((ext) => ext.replace(/(image\/|video\/|\.)/, '').toUpperCase())
    .join(', ');

  return (
    <Box>
      <Flex
        direction='column'
        align='center'
        gap={2}
        p={10}
        style={{
          borderWidth: '1px',
          borderRadius: '6px',
          backgroundColor: 'rgba(240,248,255,0.5)',
        }}
      >
        <Box width='100%'>
          {uploadSignedUrlResponse && (
            <Text isTruncated color='gray'>
              📄 {uploadSignedUrlResponse?.identifier}
            </Text>
          )}
        </Box>
        <Button
          isDisabled={isDocumentUploading}
          leftIcon={<FiUpload />}
          width='fit-content'
          borderRadius='20px'
          borderWidth='2px'
          onClick={handleUploadButtonClick}
        >
          Upload Document
        </Button>
        <input
          id={ENTITY_OWNER_DOCUMENT_UPLOAD_ID}
          type='file'
          accept={UPLOAD_FILE_ALLOWED_FORMATS}
          style={{ display: 'none' }}
          onChange={(e) => handleDocumentChange(e.target.files[0])}
        />
        {isDocumentUploading && (
          <Text fontSize='sm' color='gray'>
            Uploading...
          </Text>
        )}
      </Flex>
      {error && (
        <Text color='red.500' fontSize='sm' mt={2}>
          {error}
        </Text>
      )}
      <Text fontSize='sm' fontWeight='bold' color='gray' mt={2}>
        {`Supported files: ${validFileExtensions} max 20MB.`}
      </Text>
    </Box>
  );
}

export default UploadOwnerDocumentInput;
