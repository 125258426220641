import { Box } from '@chakra-ui/react';
import TransactionScreeningTable from 'modules/transaction-screening/TransactionScreeningTable';

function TransactionScreeningDetails() {
  return (
    <Box mt={6} mb={3}>
      <TransactionScreeningTable title={'Transaction Screening Details'} />
    </Box>
  );
}

export default TransactionScreeningDetails;
