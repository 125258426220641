import React from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import {
  RISK_ALERT_STATUS_PENDING,
  TRANSACTIONS_WITH_PENDING_RISK_ALERTS_TITLE,
  TRANSACTIONS_WITH_RISK_ALERTS_TITLE,
} from 'modules/transaction-risks/constants/constants';
import TransactionRisksList from 'modules/transaction-risks/TransactionRisksList';


export default function TransactionRisks() {
  return (
    <Box mt={6} mb={3}>
      <Tabs isFitted variant='enclosed-colored' isLazy>
        <TabList>
          <Tab>{TRANSACTIONS_WITH_PENDING_RISK_ALERTS_TITLE}</Tab>
          <Tab>{TRANSACTIONS_WITH_RISK_ALERTS_TITLE}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <TransactionRisksList
              title={TRANSACTIONS_WITH_PENDING_RISK_ALERTS_TITLE}
              alert_status={RISK_ALERT_STATUS_PENDING}
            />
          </TabPanel>
          <TabPanel>
            <TransactionRisksList
              title={TRANSACTIONS_WITH_RISK_ALERTS_TITLE}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

