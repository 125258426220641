import { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { getCreditRefundsDetailsByTxnId, processInReviewCreditRefund } from 'api/refunds';
import CreditTransactionDetails from 'modules/credit-transactions/CreditTransactionDetails';
import { showError, showToast } from 'utils/notifications';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

export default function RefundFiatCreditModal({ isOpen, onClose, transaction, refresh }) {
  const [isLoading, setIsLoading] = useState(false);
  const [refundDetailsLoading, setRefundDetailsLoading] = useState(true);
  const [fees, setFees] = useState(0);

  const toast = useToast();

  const fetchRefundDetailsByTxnId = async () => {
    if (!transaction?.parent_id) return;
    setRefundDetailsLoading(true);
    try {
      const response = await getCreditRefundsDetailsByTxnId(transaction?.id);

      setFees(response?.data?.data?.Fees || '');
    } catch (error) {
      showError(toast, 'Error fetching Refund Details', error);
    } finally {
      setRefundDetailsLoading(false);
    }
  };

  useEffect(() => {
    fetchRefundDetailsByTxnId();
  }, [transaction?.id]);

  const handleRefund = async () => {
    setIsLoading(true);
    const payload = {
      original_transaction_id: transaction?.id,
      fees: Number(fees),
    };

    try {
      await processInReviewCreditRefund(payload);
      showToast(toast, 'Success', 'Refund initiated successfully', 'success');
      refresh();
      onClose();
    } catch (error) {
      showError(toast, 'Error initiating refund', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent maxH='90vh'>
          <ModalHeader>Refund credit</ModalHeader>
          <ModalBody overflow='auto'>
            <CreditTransactionDetails transaction={transaction} />
            <LoadingErrorWrapper isLoading={refundDetailsLoading}>
              <VStack mt={4}>
                <FormControl>
                  <FormLabel>Fees</FormLabel>
                  <Input type='number' value={fees} onChange={(e) => setFees(e.target.value)} />
                </FormControl>
              </VStack>
            </LoadingErrorWrapper>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' ml={3} onClick={handleRefund} isDisabled={!fees} isLoading={isLoading}>
              Refund
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
