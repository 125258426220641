import React from 'react';
import { Box } from '@chakra-ui/react';
import {
  CREDIT_TRANSACTION_STATUSES,
  IN_REVIEW_CREDITS_TITLE,
} from 'modules/credit-transactions/constants/constants';
import PendingCreditTransactions from 'modules/credit-transactions/PendingCreditTransactions';

function CreditTransactions() {
  return (
    <Box pt='30px'>
      <PendingCreditTransactions title={IN_REVIEW_CREDITS_TITLE} status={CREDIT_TRANSACTION_STATUSES.IN_REVIEW} />
    </Box>
  );
}

export default CreditTransactions;
