import { AiOutlineDollar, AiOutlineTransaction } from 'react-icons/ai';
import { BiHomeAlt2 } from 'react-icons/bi';
import { FaArrowsRotate } from 'react-icons/fa6';
import { FiSend } from 'react-icons/fi';
import { GoArrowDownRight, GoChecklist, GoRocket } from 'react-icons/go';
import { IoWarningOutline } from 'react-icons/io5';
import { MdAccountBalance, MdArrowOutward, MdOutlinePolicy, MdOutlineVisibility, MdVerifiedUser } from 'react-icons/md';
import { PiVault } from 'react-icons/pi';
import { RiGroupLine, RiUserSearchLine } from 'react-icons/ri';
import { TbReportMedical } from 'react-icons/tb';
import { VscArrowSwap, VscTools } from 'react-icons/vsc';
import { PermissionsV2 } from 'constants/permissions_v2';
import RfiEntityDetail from 'modules/kyc/components/RfiEntityDetail';
import CreatePolicy from 'modules/policies/CreatePolicy';
import BankingPostOnboarding from 'modules/postonboarding/BankingPostOnboarding';
import TransactionRiskAlerts from 'modules/transaction-risks/TransactionRiskAlerts';
import Accounts from 'pages/accounts';
import ApproveTransactionsV2 from 'pages/approve-transactions-v2';
import AuditLogs from 'pages/auditLogs';
import Corridors from 'pages/corridors';
import CreditTransactions from 'pages/credit-transactions';
import Home from 'pages/home';
import InternalUsers from 'pages/internalusers';
import KycRfi from 'pages/kyc';
import MerchantInfo from 'pages/merchantinfo';
import Merchants from 'pages/merchants';
import OTCTrade from 'pages/otc';
import PartnerVisibility from 'pages/partner-visibility';
import Policies from 'pages/policies';
import PostOnboarding from 'pages/postonboarding';
import SettlementReports from 'pages/settlement-reports';
import SuperMerchantConfig from 'pages/supermerchant-config';
import TechSupportRequest from 'pages/techsupport';
import TransactionRisks from 'pages/transaction-risks';
import TransactionScreeningDetails from 'pages/transaction-screening';
import TransactionsV2 from 'pages/transactions_v2';
import UnmappedCreditTransactions from 'pages/unmapped-credit-transactions';
import WhiteListResources from 'pages/whitelist-resources';

export var opsDashboardRoutes = [
  {
    path: '/home',
    name: 'Home',
    icon: <BiHomeAlt2 color='inherit' />,
    component: Home,
    layout: '/dashboard',
    base_permission: '',
  },
  {
    path: '/merchant/:merchantId',
    name: 'MerchantInfo',
    icon: <RiUserSearchLine color='inherit' />,
    component: MerchantInfo,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_MERCHANTS,
  },
  {
    path: '/post-onboarding/banking/entity/:entityId',
    name: 'Banking Post Onboarding',
    icon: <GoRocket color='inherit' />,
    component: BankingPostOnboarding,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_POST_ONBOARDING,
  },
  {
    path: '/kyc/rfi/entity/:entityId',
    name: 'KYC RFI Entity',
    icon: <GoRocket color='inherit' />,
    component: RfiEntityDetail,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_KYB,
  },
  {
    path: '/transaction/:transactionId/risk-alerts',
    name: 'Transaction Risk Alerts',
    icon: <IoWarningOutline />,
    component: TransactionRiskAlerts,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_BANKING_PAYOUT,
  },
  {
    path: '/merchants',
    name: 'Merchants',
    icon: <RiUserSearchLine color='inherit' />,
    component: Merchants,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_MERCHANTS,
  },
  // {
  //   path: '/kyb-submission/banking/entity/:entityId',
  //   name: 'Banking KYB Submission',
  //   icon: <FiUpload color='inherit' />,
  //   component: BankingOnboarding,
  //   layout: '/dashboard',
  //   base_permission: PermissionsV2.MANAGE_KYB,
  // },
  // {
  //   path: '/kyb-submission/treasury/entity/:entityId',
  //   name: 'Treasury KYB Submission',
  //   icon: <FiUpload color='inherit' />,
  //   component: TreasuryOnboarding,
  //   layout: '/dashboard',
  //   base_permission: PermissionsV2.MANAGE_KYB,
  // },
  {
    name: 'Account configs',
    icon: <PiVault color='inherit' />,
    layout: '/dashboard',
    children: [
      {
        path: '/account-management',
        name: 'Add Account',
        icon: <PiVault color='inherit' />,
        component: Accounts,
        layout: '/dashboard',
        base_permission: PermissionsV2.CREATE_ACCOUNTS,
      },
      {
        path: '/corridors',
        name: 'Corridors',
        icon: <VscArrowSwap color='inherit' />,
        component: Corridors,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_CORRIDORS,
      },
      {
        path: '/post-onboarding',
        name: 'Post Onboard Entity',
        icon: <GoRocket color='inherit' />,
        component: PostOnboarding,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_POST_ONBOARDING,
      },
    ],
  },
  {
    name: 'Operations',
    icon: <FaArrowsRotate color='inherit' />,
    layout: '/dashboard',
    children: [
      {
        path: '/debits',
        name: 'Ops (Debit transactions)',
        icon: <MdArrowOutward color='inherit' />,
        component: TransactionsV2,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_BANKING_PAYOUT,
      },
      {
        path: '/unmapped-credits  ',
        name: 'Unmapped Credits',
        icon: <GoArrowDownRight color='inherit' />,
        component: UnmappedCreditTransactions,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_BANKING_PAYOUT,
      },
      {
        path: '/whitelist-resources',
        name: 'Whitelist Resources',
        icon: <MdAccountBalance />,
        component: WhiteListResources,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_KYB,
      },
      {
        path: '/otc',
        name: 'Place an OTC trade',
        icon: <AiOutlineDollar color='inherit' />,
        component: OTCTrade,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_OTC,
      },
      {
        path: '/settlement-reports',
        name: 'Settlement Reports',
        icon: <AiOutlineDollar color='inherit' />,
        component: SettlementReports,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_KYB,
      },
      {
        path: '/supermerchant-config',
        name: 'Supermerchant Config',
        icon: <AiOutlineDollar color='inherit' />,
        component: SuperMerchantConfig,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_POST_ONBOARDING,
      },
    ],
  },
  {
    name: 'Compliance Ops',
    icon: <TbReportMedical color='inherit' />,
    layout: '/dashboard',
    children: [
      {
        path: '/kyc',
        name: 'KYC',
        icon: <MdVerifiedUser color='inherit' />,
        component: KycRfi,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_KYB,
      },
      {
        path: '/credits',
        name: 'Credit Transactions',
        icon: <GoArrowDownRight color='inherit' />,
        component: CreditTransactions,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_BANKING_PAYOUT,
      },
      {
        path: '/transaction-screening',
        name: 'Transaction Screening',
        icon: <GoChecklist />,
        component: TransactionScreeningDetails,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_BANKING_PAYOUT,
      },
      {
        path: '/risky-transactions',
        name: 'Transactions With Risks',
        icon: <IoWarningOutline />,
        component: TransactionRisks,
        layout: '/dashboard',
        base_permission: PermissionsV2.MANAGE_BANKING_PAYOUT,
      },
    ],
  },
  {
    path: '/payments',
    name: 'Payments',
    icon: <FiSend color='inherit' />,
    component: ApproveTransactionsV2,
    layout: '/dashboard',
    base_permission: PermissionsV2.APPROVE_BANKING_PAYOUT,
  },
  {
    path: '/partner-visibility',
    name: 'Partner Visibility',
    icon: <MdOutlineVisibility color='inherit' />,
    component: PartnerVisibility,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_ACCOUNTS,
  },
  {
    path: '/tech-support-request',
    name: 'Request Tech Support',
    icon: <VscTools color='inherit' />,
    component: TechSupportRequest,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_TECH_SUPPORT,
  },
  // {
  //   path: '/kyb-submission',
  //   name: 'Submit Customer KYB',
  //   icon: <FiUpload color='inherit' />,
  //   component: Onboarding,
  //   layout: '/dashboard',
  //   base_permission: PermissionsV2.MANAGE_KYB,
  // },
  {
    path: '/users',
    name: 'Users',
    icon: <RiGroupLine color='inherit' />,
    component: InternalUsers,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_INTERNAL_USERS,
  },
  {
    path: '/policies/create',
    name: 'Create Policies',
    icon: <MdOutlinePolicy color='inherit' />,
    component: CreatePolicy,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_PAYMENT_POLICIES,
  },
  {
    path: '/policies',
    name: 'Policies',
    icon: <MdOutlinePolicy color='inherit' />,
    component: Policies,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_PAYMENT_POLICIES,
  },
];

export var lumosDashboardRoutes = [
  {
    path: '/merchants',
    name: 'Merchants',
    icon: <RiUserSearchLine color='inherit' />,
    component: Merchants,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_MERCHANTS,
  },
  {
    path: '/merchant/:merchantId',
    name: 'MerchantInfo',
    icon: <RiUserSearchLine color='inherit' />,
    component: MerchantInfo,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_MERCHANTS,
  },
  {
    path: '/audit-logs',
    name: 'Audit Logs',
    icon: <AiOutlineTransaction color='inherit' />,
    component: AuditLogs,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_MERCHANTS,
  },
];
