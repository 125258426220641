import React from 'react';
import { Box } from '@chakra-ui/react';
import { CREDIT_TRANSACTION_STATUSES, UNMAPPED_CREDITS_TITLE } from 'modules/credit-transactions/constants/constants';
import PendingCreditTransactions from 'modules/credit-transactions/PendingCreditTransactions';

function UnmappedCreditTransactions() {
  return (
    <Box mt={6} mb={3}>
      <PendingCreditTransactions title={UNMAPPED_CREDITS_TITLE} status={CREDIT_TRANSACTION_STATUSES.UNMAPPED} />
    </Box>
  );
}

export default UnmappedCreditTransactions;
