export const CURRENCY_LOGO_FALLBACK_SRC = 'https://via.placeholder.com/50';
export const TRANSACTIONS_TITLE = 'Transactions';
export const TRANSACTION_LIMIT = 10;
export const TRANSACTION_TABLE_HEADINGS = [
  'Date',
  'Transaction ID',
  'Description',
  'Amount',
  'Currency',
  'Transaction Type',
  'Transfer Type',
  'Status',
];
export const TRANSACTION_FOR_APPROVAL_TABLE_HEADINGS = [
  'Date',
  'Transaction ID',
  'Description',
  'Amount',
  'Currency',
  'Transfer Type',
  'Action',
];
export const TRANSACTION_STATUS = {
  INITIATED: 'initiated',
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
  IN_REVIEW: 'in_review',
  FAILED_DUE_TO_COMPLIANCE: 'failed_due_to_compliance',
  UNKNOWN: 'unknown',
  DELAYED: 'delayed',
  UNMAPPED: 'unmapped',
};
export const MERCHANT_CORRIDOR_TABLE_HEADINGS = [
  'Corridor Mapping ID',
  'Source Currency',
  'Destination Currency',
  'Type',
  'Status',
  '',
];

export const AVAILABLE_CORRIDORS_TABLE_HEADINGS = [
  'Corridor ID',
  'Source Currency Code',
  'Destination Currency Code',
  'Type',
  '',
];

export const KYB_STATUS = {
  NOT_INITIATED: 'not_initiated',
  INITIATED: 'initiated',
  SUCCEEDED: 'succeeded',
};

export const ENTITY_OWNER_DOCUMENT_TYPES = [
  {
    label: 'Tax Identification Document',
    value: 'tax_identification_document',
  },
  {
    label: 'Passport',
    value: 'passport_document',
  },
  {
    label: 'Address Proof Document',
    value: 'address_proof_document',
  },
  {
    label: 'Certificate of Incorporation',
    value: 'incorporation_certificate',
  },
  {
    label: 'Memorandum of Association',
    value: 'memorandum_of_association',
  },
  {
    label: 'Articles of Association',
    value: 'articles_of_association',
  },
  {
    label: 'Shareholder Register',
    value: 'shareholder_register',
  },
  {
    label: 'Directors Register',
    value: 'directors_register',
  },
  {
    label: 'W-8BEN',
    value: 'w_8ben',
  },
  {
    label: 'Incumbency Certificate',
    value: 'incumbency_certificate',
  },
  {
    label: 'Certificate of Good Standing',
    value: 'certificate_of_good_standing',
  },
  {
    label: 'Company Bylaws',
    value: 'company_by_laws',
  },
  {
    label: 'RFI Document',
    value: 'rfi_document',
  },
  {
    label: 'Company Address Proof',
    value: 'company_address_proof_document',
  },
];

export const ENTITY_OWNER_DOCUMENT_UPLOAD_ID = 'entity_owner_document_upload';

export const FILE_SIZE = {
  ONE_MB: 1048576,
  TWO_MB: 20971520,
  THREE_MB: 3145728,
};

export const FILE_MIME_TYPE = {
  'application/pdf': 'pdf',
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'image/bmp': 'bmp',
};

export const FILE_EXTENSION_TO_TYPE_MAPPING = {
  pdf: 'application/pdf',
  jpeg: 'image/jpeg',
  png: 'image/png',
  bmp: 'image/bmp',
};

export const INPUT_FILE_FORMATS = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  PDF: '.pdf',
  BMP: '.bmp',
  MP4: 'video/mp4',
};

export const FILE_EXTENSION_TO_INPUT_FORMAT_MAPPING = {
  pdf: INPUT_FILE_FORMATS.PDF,
  jpeg: INPUT_FILE_FORMATS.JPEG,
  png: INPUT_FILE_FORMATS.PNG,
  bmp: INPUT_FILE_FORMATS.BMP,
  jpg: INPUT_FILE_FORMATS.JPG,
  mp4: INPUT_FILE_FORMATS.MP4,
};

export const TXN_STEP_COLUMNS = [
  'Date',
  'Time',
  'Type',
  'Category ID',
  'Source Partner Code',
  'Destination Partner Code',
  'Source Amount',
  'Source Currency',
  'Destination Currency',
  'Status',
  'Failure Reason',
  'Action',
];

export const TRANSACTION_QUOTES_VERSION_V2 = 'V2';

export const ELIGIBLE_PARTNER_CODES_FOR_KYT = ['ZAND', 'FIREBLOCKS_UAE'];
