import { useState } from 'react';
import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { getSuperMerchantConfig } from 'api/super-merchant';
import { COLORS, STATUS_ITEMS } from 'constants/onboarding.constants';
import CreateCorridorModal from 'components/CreateCorridors/CreateCorridorModal';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

const getRandomColor = () => COLORS[Math.floor(Math.random() * COLORS.length)];

const SuperMerchantConfig = () => {
  const [merchantId, setMerchantId] = useState('');
  const [loading, setLoading] = useState(false);
  const [onboardingConfig, setOnboardingConfig] = useState(null);
  const [openCorridorModal, setOpenCorridorModal] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await getSuperMerchantConfig(merchantId);

      setOnboardingConfig(data?.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={8} bg='gray.50'>
      <Heading as='h1' size='lg' mb={4}>
        Super Merchant Config
      </Heading>

      <Grid templateColumns='repeat(2, 1fr)' gap={4} mb={6}>
        <FormControl>
          <FormLabel fontSize='md' fontWeight={600}>
            Super Merchant ID
          </FormLabel>
          <Input
            value={merchantId}
            onChange={(e) => setMerchantId(e.target.value)}
            placeholder='Enter Merchant ID'
            onKeyDown={(e) => e.key === 'Enter' && merchantId && handleSearch()}
          />
        </FormControl>
        <GridItem alignSelf='end'>
          <Button colorScheme='blue' onClick={handleSearch} isDisabled={!merchantId}>
            Proceed
          </Button>
        </GridItem>
      </Grid>

      <LoadingErrorWrapper isLoading={loading} errorTitle='Error fetching onboarding changes.' error={error}>
        {onboardingConfig ? (
          <Box rounded='lg' bg='white' p={6} boxShadow='lg' position='relative'>
            <Button
              colorScheme='teal'
              size='sm'
              position='absolute'
              top={4}
              right={4}
              onClick={() => setOpenCorridorModal(true)}
            >
              Update
            </Button>
            <Stack spacing={2}>
              <Text>
                <strong>Name:</strong> {onboardingConfig?.name}
              </Text>
              <Text>
                <strong>Fiat Payout Provider:</strong> {onboardingConfig?.fiat_payout_provider}
              </Text>
              <Text>
                <strong>Crypto Payout Provider:</strong> {onboardingConfig?.crypto_payout_provider || 'N/A'}
              </Text>
            </Stack>

            <Heading as='h3' size='sm' color='blue.500' mt={4} mb={2}>
              Corridors
            </Heading>
            <Box overflowY='auto' maxHeight='400px' p={2} borderWidth='1px' borderRadius='md'>
              <Grid templateColumns='repeat(auto-fill, minmax(300px, 1fr))' gap={4}>
                {onboardingConfig?.corridors?.map((corridor, index) => (
                  <Box key={index} p={4} borderWidth='1px' borderRadius='md' bg='gray.100'>
                    <Text>
                      <strong>Corridor Name:</strong> {corridor?.corridor_name}
                    </Text>
                    <Text>
                      <strong>ABS Fee:</strong> {corridor?.fees?.ABS}
                    </Text>
                    <Text>
                      <strong>BPS Fee:</strong> {corridor?.fees?.BPS}
                    </Text>
                  </Box>
                ))}
              </Grid>
            </Box>

            <Flex wrap='wrap' gap={3} mt={4}>
              {STATUS_ITEMS.map((item) => (
                <Badge
                  key={item}
                  colorScheme={onboardingConfig[`is_third_party_${item}_disabled`] ? 'red' : 'green'}
                  fontSize='sm'
                  p={2}
                >
                  {item.replace('_', ' ').toUpperCase()} :{'  '}
                  {onboardingConfig[`is_third_party_${item}_disabled`] ? 'Disabled' : 'Enabled'}
                </Badge>
              ))}
            </Flex>

            <Heading as='h3' size='sm' mt={6} color='blue.500'>
              Account Currencies
            </Heading>
            <Flex wrap='wrap' gap={2} mt={2}>
              {onboardingConfig?.accounts_currencies?.map((currency) => (
                <Badge key={currency} colorScheme={getRandomColor()} fontSize='sm' p={2}>
                  {currency}
                </Badge>
              ))}
            </Flex>
          </Box>
        ) : (
          !loading && (
            <Alert status='info' borderRadius='md' mt={4}>
              <AlertIcon />
              Enter a Merchant ID to view details.
            </Alert>
          )
        )}
      </LoadingErrorWrapper>

      {openCorridorModal && (
        <CreateCorridorModal
          isOpen={openCorridorModal}
          onClose={() => setOpenCorridorModal(false)}
          merchantId={merchantId}
          isSuperMerchant
          fetchOnboardingConfig={handleSearch}
        />
      )}
    </Box>
  );
};

export default SuperMerchantConfig;
