import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Box, Button, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { getSettlementReports } from 'api/settlement-reports';
import FileDetailsDrawer from 'modules/settlement-reports/components/FileDetailsDrawer';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import Search from 'components/SearchBox/Search';

const SettlementReports = () => {
  const [settlementReports, setSettlementReports] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchSettlementReports = async (searchParam) => {
    setLoading(true);
    try {
      const response = await getSettlementReports(searchParam);

      setSettlementReports(response?.data?.data || {});
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettlementReports(searchTerm);
  }, [searchTerm]);

  const totalReports = useMemo(
    () => Object?.keys(settlementReports)?.reduce((acc, key) => acc + settlementReports[key]?.files?.length, 0),
    [settlementReports]
  );

  const openDrawer = (report) => {
    setSelectedReport(report);
    setDrawerOpen(true);
    setCurrentPage(1);
  };

  return (
    <Box maxWidth='100%' p={4} borderRadius='lg' boxShadow='sm' bg='white'>
      <Heading as='h1' size='md' mb={4} mt={4} color='gray.700'>
        Settlement Reports
        {totalReports > 0 && (
          <Badge colorScheme='gray' rounded='full' px={2.5} py={0.5} ml={2}>
            {totalReports}
          </Badge>
        )}
      </Heading>

      <Search searchType={'By File name'} setSearchTerm={setSearchTerm} debounceTime={500} />

      <LoadingErrorWrapper isLoading={loading} errorTitle='Error fetching Settlement Reports' error={error}>
        <TableContainer borderRadius='lg' border='1px solid' borderColor='gray.200' overflowX='auto' mt={4}>
          <Table variant='simple' size='md'>
            <Thead bg='gray.100' position='sticky' top={0} zIndex={1}>
              <Tr>
                <Th fontSize='sm' fontWeight='bold' color='gray.700' py={3}>
                  Report ID
                </Th>
                <Th fontSize='sm' fontWeight='bold' color='gray.700' py={3}>
                  Client Name
                </Th>
                <Th fontSize='sm' fontWeight='bold' color='gray.700' py={3}>
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(settlementReports)?.map((key) => {
                const { client_name } = settlementReports[key];

                return (
                  <Tr
                    key={key}
                    cursor='pointer'
                    fontSize={'sm'}
                    onClick={() => openDrawer(settlementReports[key])}
                    _hover={{ bg: 'gray.50' }}
                  >
                    <Td color='gray.600' fontWeight='bold'>
                      {key}
                    </Td>
                    <Td color='gray.600' fontWeight='bold'>
                      {client_name ?? '-'}
                    </Td>
                    <Td>
                      <Button colorScheme='blue' size='sm'>
                        View Details
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </LoadingErrorWrapper>

      <FileDetailsDrawer
        isDrawerOpen={isDrawerOpen}
        setDrawerOpen={setDrawerOpen}
        selectedReport={selectedReport}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Box>
  );
};

export default SettlementReports;
