export const TRANSACTION_SCREENING_TABLE_HEADINGS = [
  'Transaction ID',
  'Date',
  'Transfer Method',
  'Partner Code',
  'Currency',
  'Amount',
  'Kyt Status',
  'Travel Rule Status',
  'Chainalysis Status',
];
