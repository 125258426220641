import { TRANSACTION_STATUS } from 'modules/merchantinfo/constants/constants';

export const formatTransactionTime = (dateString) => {
  const date = new Date(dateString);

  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  return date.toLocaleString('en-US', options);
};

export const formatTransactionStatus = (status) => {
  switch (status) {
    case TRANSACTION_STATUS.INITIATED:
      return 'IN PROCESS';
    case TRANSACTION_STATUS.FAILED:
      return 'FAILED';
    case TRANSACTION_STATUS.SUCCEEDED:
      return 'SUCCESSFUL';
    case TRANSACTION_STATUS.IN_REVIEW:
      return 'IN REVIEW';
    case TRANSACTION_STATUS.FAILED_DUE_TO_COMPLIANCE:
      return 'COMPLIANCE FAILED';
    case TRANSACTION_STATUS.DELAYED:
      return 'DELAYED';
    case TRANSACTION_STATUS.UNMAPPED:
      return 'UNMAPPED';
    case TRANSACTION_STATUS.UNKNOWN:
      return 'UNKNOWN';
    default:
      return 'N/A';
  }
};

export const getTransactionStatusBadgeColor = (status) => {
  switch (status) {
    case TRANSACTION_STATUS.SUCCEEDED:
      return 'green';
    case TRANSACTION_STATUS.FAILED:
      return 'red';
    case TRANSACTION_STATUS.IN_REVIEW:
      return 'yellow';
    default:
      return 'gray';
  }
};
