export const INTERNAL_DASHBOARD_TITLE = 'OPS DASHBOARD';

export const PARTNER_CODE_NIUM_SG = 'NIUM_SG';
export const PARTNER_CODE_NIUM_USA = 'NIUM_USA';
export const PARTNER_CODE_LAYER2 = 'LAYER2';
export const PARTNER_CODE_FIREBLOCKS = 'FIREBLOCKS';
export const PARTNER_CODE_FIREBLOCKS_UAE = 'FIREBLOCKS_UAE';
export const PARTNER_CODE_ZAND = 'ZAND';
export const PARTNER_TYPE_FIAT = 'FIAT';
export const PARTNER_TYPE_CRYPTO = 'CRYPTO';

export const FIREBLOCKS_BASE_CURRENCIES = ['ETH', 'MATIC', 'BNB', 'SOL'];

export const ENTITY_CONFIG_PARTNER_TYPE_FIAT_PAYOUTS = 'FiatPayoutsPartner';
export const ENTITY_CONFIG_PARTNER_TYPE_CRYPTO_PAYOUTS = 'CryptoPayoutsPartner';
export const KYB_SERVICE_BANKING_ACCOUNT = 'banking_account';

export const SMB = 'SMB';
export const TMS = 'TMS';

export const UPLOAD_FILE_ALLOWED_FORMATS = 'application/pdf,image/jpeg,image/jpg,image/png,jpeg,jpg,png,video/mp4';

export const TOAST_STATUS_SUCCESS = 'success';
