import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Box, Center, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import * as ROUTES from 'constants/routes';
import { TRANSACTION } from 'constants/transactionDetails';
import { RFI_STATUS_TABLE_HEADERS, RFI_SUMMARY_TABLE_HEADERS } from 'modules/kyc/constants/constants';
import { formatRfiStatus, getRfiStatusBadgeColor } from 'modules/kyc/utils/utils';
import { formatDateTime, snakeToTitleCase } from 'utils/utils';
import Search from 'components/SearchBox/Search';

export default function RFiList({ title = '', rfiList = [], isRfiSummary = false, onSummaryRowClick, tab = 0 }) {
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  const rfiTableHeadings = isRfiSummary ? RFI_SUMMARY_TABLE_HEADERS : RFI_STATUS_TABLE_HEADERS;

  const filteredRfiList = useMemo(() => {
    if (!searchTerm) return rfiList;

    return rfiList?.filter((rfi) => {
      return (
        rfi?.entity_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        rfi?.entity_id?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [rfiList, searchTerm]);

  const handleRowClick = (entityId) => {
    const route = `${ROUTES.KYC_RFI_ENTITY_DETAILS_ROUTE}${entityId}?tab=${tab}`;

    history.push(route, { entityId });
  };

  return (
    <Box overflowX='auto' maxWidth='100%' p={4} borderRadius='lg' boxShadow='sm' bg='white'>
      <Heading as='h1' size='md' mb={3} mt={4} color='gray.700'>
        {title}{' '}
        {!isRfiSummary && (
          <Badge colorScheme='blue' variant='solid' px={2} ml={1}>
            {rfiList?.length}
          </Badge>
        )}
      </Heading>

      {!isRfiSummary && <Search searchType={TRANSACTION} setSearchTerm={setSearchTerm} />}

      <TableContainer borderRadius='lg' border='1px solid' borderColor='gray.200' overflowX='auto' mt={4}>
        <Table variant='simple' size='md'>
          <Thead bg='gray.100' position='sticky' top={0} zIndex={1}>
            <Tr>
              {rfiTableHeadings?.map((heading, idx) => (
                <Th key={idx} fontSize='sm' fontWeight='bold' color='gray.700' py={3}>
                  {heading}
                </Th>
              ))}
            </Tr>
          </Thead>

          {isRfiSummary ? (
            <Tbody>
              {rfiList?.map((rfi, idx) => (
                <Tr
                  key={idx}
                  fontSize='sm'
                  cursor='pointer'
                  onClick={() => onSummaryRowClick(rfi?.status)}
                  _hover={{ bg: 'gray.50' }}
                >
                  <Td fontWeight='medium'>{snakeToTitleCase(rfi?.status)}</Td>
                  <Td>{rfi?.less_than_24_hours_count}</Td>
                  <Td color='gray.600'>{rfi?.between_24_and_48_hours_count}</Td>
                  <Td color='gray.600'>{rfi?.more_than_48_hours_count}</Td>
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Tbody>
              {filteredRfiList?.map((rfi) => (
                <Tr
                  key={rfi?.entity_id}
                  fontSize='sm'
                  cursor='pointer'
                  _hover={{ bg: 'gray.50' }}
                  onClick={() => handleRowClick(rfi?.entity_id)}
                >
                  <Td>{formatDateTime(rfi?.date)}</Td>
                  <Td color='gray.600' fontWeight='bold'>
                    {rfi?.entity_id ?? '-'}
                  </Td>
                  <Td color='gray.600' fontWeight='bold'>
                    {rfi?.entity_name ?? '-'}
                  </Td>
                  <Td>
                    <Badge colorScheme={getRfiStatusBadgeColor(rfi?.status)} rounded='full' px={3}>
                      {formatRfiStatus(rfi?.status)}
                    </Badge>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>

      {!rfiList?.length && (
        <Center>
          <Text color='gray.500' mt={4}>
            No {title} found.
          </Text>
        </Center>
      )}
    </Box>
  );
}
