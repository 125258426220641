export const TRANSACTIONS_WITH_PENDING_RISK_ALERTS_TITLE = 'Transactions with Pending Risk Alerts';
export const TRANSACTIONS_WITH_RISK_ALERTS_TITLE = 'Transactions with Risk Alerts';
export const RISK_ALERT_STATUS_PENDING = 'PENDING';

export const RISK_ALERT_STATUS = {
  SUSPICIOUS: 'SUSPICIOUS',
  PENDING_REVIEW: 'PENDING_REVIEW',
  PENDING_RFI: 'PENDING_RFI',
  UNDER_MONITORING: 'UNDER_MONITORING',
  ESCALATED_TO_L2: 'ESCALATED_TO_L2',
  FALSE_POSITIVE: 'FALSE_POSITIVE',
};

export const getRiskAlertStatusColor = (status) => {
  switch (status) {
    case RISK_ALERT_STATUS.SUSPICIOUS:
      return 'red';
    case RISK_ALERT_STATUS.PENDING_REVIEW:
      return 'yellow';
    case RISK_ALERT_STATUS.PENDING_RFI:
      return 'orange';
    case RISK_ALERT_STATUS.UNDER_MONITORING:
      return 'yellow';
    case RISK_ALERT_STATUS.ESCALATED_TO_L2:
      return 'purple';
    case RISK_ALERT_STATUS.FALSE_POSITIVE:
      return 'gray';
    default:
      return 'gray';
  }
};

export const RISK_ALERT_STATUSES = [
  RISK_ALERT_STATUS.PENDING_REVIEW,
  RISK_ALERT_STATUS.ESCALATED_TO_L2,
  RISK_ALERT_STATUS.FALSE_POSITIVE,
  RISK_ALERT_STATUS.PENDING_RFI,
  RISK_ALERT_STATUS.UNDER_MONITORING,
  RISK_ALERT_STATUS.SUSPICIOUS,
];