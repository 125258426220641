import { Link } from 'react-router-dom';
import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import {
  ACCOUNTS_ROUTE,
  KYC_ROUTE,
  MERCHANTS_ROUTE,
  PARTNER_VISIBILITY_ROUTE,
  PAYMENTS_ROUTE,
  TECH_SUPPORT_REQUEST,
  TRANSACTIONS_ROUTE,
} from 'constants/routes';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
// import MetricsButton from 'components/MetricsButton/MetricsButton';

export default function Home() {
  return (
    <Flex flexDirection='column' pt='30px'>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6}>
        <Link to={MERCHANTS_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Merchants
                </Text>
                <Text color='gray.400'>View and manage merchant information and details.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={ACCOUNTS_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Account Configurations
                </Text>
                <Text color='gray.400'>Add and manage entity accounts on Partners platform.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={TRANSACTIONS_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Operations
                </Text>
                <Text color='gray.400'>
                  Manage debit transactions, their processing status, whitelist resources or place an OTC trade.
                </Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={KYC_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Compliance Operations
                </Text>
                <Text color='gray.400'>Handle KYC requests and compliance-related operations.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={PAYMENTS_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Payments
                </Text>
                <Text color='gray.400'>Review and process pending banking payments.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={PARTNER_VISIBILITY_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Partner Visibility
                </Text>
                <Text color='gray.400'>Monitor partner account balances and risk assessments.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={TECH_SUPPORT_REQUEST}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Request Tech Support
                </Text>
                <Text color='gray.400'>Tech Turmoil? Request Tech Support Login to verify customer data.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>
      </SimpleGrid>

      {/* <MetricsButton /> */}
    </Flex>
  );
}
