export const WHITELIST_TABLE_HEADINGS = [
  'Merchant Id',
  'Account Number',
  'Entity Name',
  'Partner Name',
  'Status',
  'Created At',
  'Updated At',
];

export const RESOURCE_WHITELIST_STATUS = {
  ACTIVE: 'active',
  REJECTED: 'rejected',
  IN_REVIEW: 'in_review',
};
